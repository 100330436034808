import Text from 'components/Text';
import { Box, Grid, Link, List, ListItem, Typography } from '@mui/material';
import { footerLinkList } from './FooterLinksList';
import './lifelabs.footer.scss';
import Linkedin from 'assets/footer-assets/linkedin.png';
import Twitter from 'assets/footer-assets/twitter.png';
import Facebook from 'assets/footer-assets/facebook.png';
import Youtube from 'assets/footer-assets/youtube.png';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import useMobile from 'hooks/useMobile';

const LifeLabsfooter = () => {
    const selectedProvince = useSelector((state) => state.selectedProvince)
    return (
        <Grid container className='footer__container'>
            <FooterTop />
            <FooterBody selectedProvince={selectedProvince}/>
            <FooterLinks selectedProvince={selectedProvince}/>
        </Grid>
    )
}

const FooterTop = () => {
    return (
        <Grid container className="footerTop">
            <Grid item xs={12}>
                <Text.Bold className="title">Land Acknowledgement</Text.Bold>
            </Grid>
            <Grid item xs={12} className="footer-heading">
                <Typography variant="body1" className="footer-heading-text">
                    {"At LifeLabs, we care about people's health and are committed to empowering a healthier you. We humbly acknowledge that our company conducts its operations across the vast and diverse lands of what is now called Canada, home to the traditional territories of First Nations, Inuit, and Métis Peoples, each with their distinct cultures, languages, and histories. We are mindful of their unique rights and perspectives, actively seeking engagement and partnership, and striving to build sustainable relationships. Our vision of a more inclusive and equitable future guides us in providing caring, efficient, reliable, and high-quality services, honoring the contributions of Indigenous cultures to Canada's tapestry."}
                </Typography>
            </Grid>
        </Grid>
    )
}

const FooterBody = ({ selectedProvince }) => {
    const isMobile = useMobile();
    return (
        <Grid container className='footerbody'>
            {footerLinkList.map((menu, index) => (
                <Grid item key={index} className='footer-listitem'>
                    <MenuItem
                        title={menu.title}
                        link={menu.link}
                        subMenuItems={menu.menuItem}
                        selectedProvince={selectedProvince}
                        isMobile={isMobile}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const FooterLinks = ({selectedProvince}) => {
    return (
        <Grid item className="footer-links-container">
            <Stack direction={{ xs: 'column', md: "row" }} alignItems="center">
                <Grid item md={9} sm={12} className="footer-links-section">
                    <Stack className="footer-links-list" direction="row">
                        <Box  className='footer-links-copyright'>
                            <Typography variant="body1" className="copyright">
                                {`Copyright ©${new Date().getFullYear()}`}
                            </Typography>
                        </Box>
                        <Box className='footer-links-responsive'>
                            <Link
                                href={"https://www.lifelabs.com/privacy-policy/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Privacy Policy
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/lifelabs-vulnerability-disclosure-program-policy/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Report A Vulnerability
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/accessibility/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Accessibility
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/terms-of-service/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Terms of Service
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/sitemap/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                                sx={{
                                    display:{
                                        md: 'none',
                                        xs: "inherit"
                                    }
                                }}
                            >
                                Sitemap
                            </Link>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={2} className="footer-links-section">
                    <List className="footer-links-list footer-kronos">
                            <Link className="social-buttons" href="https://lifecentre.lifelabs.com" target="_blank" underline='none'>
                                LifeCentre
                            </Link>
                        <span className="footer-kronos">
                            <Link className="social-buttons" href="https://kronos.lifelabs.com/wfc/navigator/logon" target="_blank" underline='none'>
                                Kronos
                            </Link>
                        </span>
                    </List>
                </Grid>
                <Grid item md={4} className="social-links">
                    <div>
                        <Link className="linkedin" href="https://www.linkedin.com/company/lifelabs-medical-laboratory-services" title="Linkedin" aria-label="Linkedin">
                            <img src={Linkedin} alt="Linkedin" className='footer-assets'/>
                        </Link>
                        <Link className="twitter" href="https://twitter.com/intent/follow?source=followbutton&amp;variant=1.0&amp;screen_name=lifelabs" title="Twitter" aria-label="Twitter">
                            <img src={Twitter} alt="Twitter" className='footer-assets'/>
                        </Link>
                        <Link className="facebook" href="https://www.facebook.com/LifeLabsLaboratory" title="Facebook" aria-label="Facebook">
                            <img src={Facebook} alt="Facebook" className='footer-assets'/>
                        </Link>
                        <Link className="youtube" href="https://www.youtube.com/channel/UC6uFwF_U0Yej4mVxHQMAy8g" title="Youtube" aria-label="Youtube">
                            <img src={Youtube} alt="Youtube" className='footer-assets'/>
                        </Link>
                    </div>
                </Grid>
            </Stack>
            <Link
                href={"https://www.lifelabs.com/sitemap/" + "?myProvince="+selectedProvince?.toLowerCase()}
                color="inherit"
                underline="none"
                className="footer-links-text"
                sx={{
                    display:{
                        md: 'inherit',
                        xs: "none",
                    }
                }}
            >
                Sitemap
            </Link>
        </Grid>
    )
}

const MenuItem = ({ title, link, subMenuItems, selectedProvince, isMobile }) => {
    return (
        <div>
            <Typography variant="subtitle1">
                <Link href={link+"?myProvince="+selectedProvince?.toLowerCase()} className="link">
                    {(title === 'Contact Us' && !isMobile) ? '' : title}
                </Link>
            </Typography>
            {subMenuItems && (
                <List className='footer-section-list'>
                    {subMenuItems.map((item, index) => (
                        <ListItem key={index} className="listItem">
                            <Link href={item.link+"?myProvince="+selectedProvince?.toLowerCase()} className="subMenuItem">
                                {(item.title === 'Contact Us' && !isMobile) ? <span className="link contact-us">{item.title}</span> : item.title}
                            </Link>
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default LifeLabsfooter