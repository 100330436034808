import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Stack, Grid, Box } from '@mui/material'
import Filters from 'features/filters/Filters'
import SearchBar from 'features/searchBar/SearchBar'
import SearchResults from 'features/searchresults/SearchResults'
import { useLazyPostConfigsQuery } from 'store/services/configsApi'
import { usePostLocationsMutation } from 'store/services/locationApi'
import { setConfigs } from 'store/reducers/configReducer'
import MyVisitPopover from 'pages/mcc/components/MyVisitPopover'
import SortAndFilterMobileView from 'features/filters/SortAndFilterMobileView'
import useMobile from 'hooks/useMobile'
import ErrorBoundary from 'features/Error/ErrorBoundary'
import {PlacesServiceProvider} from 'features/searchresults/maps/PlaceServiceContext'

const Body = () => {
	const dispatch = useDispatch();
	/* istanbul ignore next */
	const [postLocations, { data, isLoading }] = usePostLocationsMutation('', {
		pollingInterval: parseInt(process.env.REACT_APP_REFRESH_INTERVAL) || 60000,
	})

	const [showMyVisit, setShowMyVisit] = useState(false)
	const [postConfigs, { data: configs }] = useLazyPostConfigsQuery()
	const isMobile = useMobile();

	useEffect(() => {
		/* istanbul ignore if */
		if (data) {
			postConfigs(data.siteIds || [])
		}
	}, [data, postConfigs])

	useEffect(() => {
		/* istanbul ignore if */
		if (configs) {
			dispatch(setConfigs({ ...configs }))
		}
	}, [configs, dispatch, setConfigs])

	return (
		<PlacesServiceProvider>
		<Grid role="main" sx={{ marginTop: {xs: '150px', md: '0px'}}}>
			{(postLocations.alertCode === 500) && <ErrorBoundary errorType={"500"}/>}
			{((postLocations.alertCode === 400 || postLocations.alertCode === 300)) && <ErrorBoundary />}
			<Stack sx={{ width: '100%' }}>
				{isMobile && <SortAndFilterMobileView locationList={data} />}
				<SearchBar
					searchId="search-user-address"
					setShowMyVisit={setShowMyVisit}
					postLocations={postLocations}
					data={data}
				/>
				{!isMobile && <Filters />}
				<SearchResults
					searchResults={data}
					isResultsLoading={isLoading}
					setShowMyVisit={setShowMyVisit}
				/>
			</Stack>

			<MyVisitPopover
				showMyVisitDialog={showMyVisit}
				handleClose={() => setShowMyVisit(false)}
			/>
		</Grid>
		</PlacesServiceProvider>
	)
}

export default Body
