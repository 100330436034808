//Code
export const API_CODE = process.env.REACT_APP_API_CODE

// Common
export const PROTOCOL = 'https://'
export const HOST_PREPEND = PROTOCOL + 'af-exc-pwt-csap-'
export const HOST_APPEND = 'api-qa.azurewebsites.net'

//APIs and code
// export const LOCATION_API = HOST_PREPEND + 'location' + HOST_APPEND
export const LOCATION_SITE_ID_API =
	HOST_PREPEND + 'locationbysiteid' + HOST_APPEND
export const LOCATION_APPOINTMENT_SLOTS_API =
	HOST_PREPEND + 'locationappointmentslots' + HOST_APPEND
export const CUSTOMER_API = HOST_PREPEND + 'customer' + HOST_APPEND
export const APPOINTMENTS_API = HOST_PREPEND + 'appointments' + HOST_APPEND
export const CONFIG_API = HOST_PREPEND + 'getconfigs' + HOST_APPEND
export const RESERVATION_API = HOST_PREPEND + 'reservation' + HOST_APPEND
export const DEVICE_API = HOST_PREPEND + 'device' + HOST_APPEND

export const EXTEND_RESERVATION_API =
	HOST_PREPEND + 'extendreservationtime' + HOST_APPEND
export const EXTEND_RESERVATION_API_CODE = process.env.REACT_APP_API_CODE_1
export const RESERVATION_API_CODE = process.env.REACT_APP_API_CODE
export const POST_CUSTOMER_API_CODE = process.env.REACT_APP_API_CODE_4
export const APPOINTMENT_API_CODE = process.env.REACT_APP_API_CODE_3
export const DEVICE_API_CODE = process.env.REACT_APP_API_CODE_5
export const SYNC_MCC_API_CODE = process.env.REACT_APP_API_CODE_6

// Refactored API Path and Code

export const LOCATION_API = HOST_PREPEND + 'location' + HOST_APPEND

// TimeSlots
export const TIMESLOTS_API_CODE = process.env.REACT_APP_LOCATION_TIME_SLOTS_API_CODE

// Location By SiteId
export const LOCATION_SITE_ID_API_CODE = process.env.REACT_APP_LOCATION_SITE_ID_API_CODE

// Update Reservation Time
export const UPDATE_RESERVATION_CODE = process.env.REACT_APP_UPDATE_RESERVATION_CODE

//Paths
export const COMMON_PATH = process.env.REACT_APP_COMMON_PATH
export const LOCATION_PATH = '/location'
export const LOCATIONS_PATH = '/locations'
export const TIME_PATH = '/time'
export const CUSTOMER_PATH = '/customer'
export const CONFIG_PATH = '/configs'
export const APPOINTMENTS_PATH = '/appointments'
export const APPOINTMENT_PATH = '/appointment'
export const CANCEL_PATH = '/cancel'
export const CONFIRM_PATH = '/confirm'
export const RESERVE_PATH = '/reserve'
export const EXTEND_RESERVATION_PATH = '/extend'
export const RESERVATIONS_PATH = '/reservations'
export const BOOKING_PATH = '/booking'
export const DEVICE_PATH = '/device'
export const DEVICE_HISTORY_PATH = '/devicehistory'
export const SYNC_CUSTOMER_DATA = 'SyncMCCPWTCustomer'
export const PROXY_PATH = '/proxy'
export const CONNECT_TOKEN_PATH = '/connect/token'
export const EARLIEST_TIME_PATH = '/earliestAppt'
export const SITES_PATH = '/sites'
export const EASY_CANCEL = '/easycancel'
export const GEOCODE_PATH = '/geocode'

// MCC PATHs
export const MCC_HOST_PREPEND = PROTOCOL + 'ca-c-exc-upp-on-'
export const MCC_HOST_PREPEND_BC = PROTOCOL + 'ca-c-exc-upp-bc-'
export const MCC_BASE_URL = MCC_HOST_PREPEND + HOST_APPEND
export const MCC_BASE_URL_BC = MCC_HOST_PREPEND_BC + HOST_APPEND
export const MCC_CUSTOMER_API = '/AccountSettings/GetAccountInformation'

// Proxy API Endpoint
export const NEW_HOST_PREPEND = PROTOCOL + process.env.REACT_APP_MCC_PROXY_URL + PROXY_PATH
export const TOKEN_URL = PROTOCOL + process.env.REACT_APP_AZURE_AD_TOKEN_URL

