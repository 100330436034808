import { useState, useEffect } from 'react'
import moment from 'moment'
import { Grid, InputLabel, Stack, Typography } from '@mui/material'
import IconGrid from 'components/data-display/IconGrid/IconGrid'

import InfoAccordion from 'components/accordions/InfoAccordion'
import RoundedButton from 'components/buttons/RoundedButton'
import ConfirmDialog from 'components/ConfirmDialog'

import { Strings } from 'utils/Strings'
import { testAttr } from 'utils/test/test.utils'

import Plus from 'assets/Plus'
import Minus from 'assets/Minus'
import User from 'assets/User'
import { ReactComponent as ConfirmCalendar } from 'assets/ConfirmCalendar.svg'
import { ReactComponent as TimerIcon } from 'assets/Timer-Icon.svg'

import { useNavigate } from 'react-router-dom'
import {
	LOGIN,
	MCC_APPT_CONFIRMED,
	MCC_CONFIRM_APPT,
	ID4_ON,
	ID4_BC,
} from 'utils/constants/routes-constants'
import {
	resetTimeSlotsApi,
	useLazyPostTimeslotsQuery,
} from 'store/services/locationTimeSlotsApi'
import { useDispatch, useSelector } from 'react-redux'
import {
	APPOINTMENT_STATES,
	APPOINTMENT_STATUS,
	resetAppointment,
	resetAppointmentBookingTimeLeft,
	resetAppointReserveId,
	updateAppointment,
	updateAppointmentReserveId,
	updateAppointmentState,
	updateSelectedAppointmentDateTime,
	resetSelectedAppointmentDateTime
} from './appointmentSlice'
import Text from 'components/Text'
import { calculateDurationInDays, calculateEndTime } from 'utils/dateTime.utils'
import AppointmentHints from './AppointmentHints'
import AppointmentTimeSlot from './AppointmentTimeSlot'
import DatePicker from 'components/input/DatePicker'
import {
	resetBookSessionApi,
	useLazyPostBookSessionQuery,
} from 'store/services/reservationApi'
import useCountdownTimer from 'hooks/useCountDownTimer'
import Toast from 'components/feedback/toast/Toast'
import { resetFilters } from 'features/filters/filterSlice'
import {
	resetAppointmentApi,
	useLazyPostConfirmAppointmentQuery,
} from 'store/services/appointmentApi'
import {
	useLazyCreateDeviceQuery,
	useUpdateDeviceHistoryMutation,
} from 'store/services/deviceApi'
import { setDeviceHistory } from 'store/reducers/deviceHistoryReducer'
import {
	APPOINTMENT_TYPE,
	BookingType,
	DEVICE_HISTORY_STATUSES,
} from 'utils/constants/common.constants'
import { saveState } from 'store/reducers/appStateReducer'
import { updateSelectedProvince } from 'store/reducers/selectedProvinceReducer'
import SessionExpire from './SessionExpire'
import {
	checkOpenDate,
	createReservationTimer,
	getItemFromBrowserStorage,
	getProvinceInitials,
	setItemFromBrowserStorage,
} from 'utils/common.utils'
import { resetMccCustomerApi } from 'store/services/mccCustomerApi'
import { microsoftSupportedTimezones } from 'utils/constants/time-constants'

const Appointment = ({
	selectedSpeciltyTest = {},
	selectedFilterDate,
	selectedEarliest,
}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [openDialog, setOpenDialog] = useState(false)
	const [provinceDialogLocation, setProvinceDialogLocation] = useState()
	const [isExpanded, setIsExpanded] = useState(false)
	const [selectedDate, setSelectedDate] = useState()
	const [selectedTime, setSelectedTime] = useState()
	const [timeOpen, setTimeOpen] = useState(false)
	const [timeSlotApiCalled, setTimeSlotApiCalled] = useState(false)
	const [alertCode, setAlertCode] = useState()
	const [fromDiscardButton, setFromDiscardButton] = useState(false)

	const [postTimeSlots, { data: timeSlots }] = useLazyPostTimeslotsQuery()
	const [postBookSession, { data: bookSession }] = useLazyPostBookSessionQuery()
	const [createDeviceHistory] = useLazyCreateDeviceQuery()
	const selectedProvince = useSelector((state) => state.selectedProvince)

	const selectedLocation = useSelector(
		(state) => state.appointment.appointmentLocation
	)
	const { source, customerId } = useSelector((state) => state.search)

	const appointmentDetails = useSelector(
		(state) => state.appointment?.appointment
	)
	const applicationSettings = useSelector(
		(state) => state.config?.applicationSettings
	)

	const [testPrepInformation, setTestPrepInformation] = useState(
		Strings.STANDARD_PREP_BODY
	)

	const [openToast, setOpenToast] = useState(false)
	const specialtyTests =
		useSelector((state) => state.config.specialityTest) || []

	const appointment = useSelector((state) => state.appointment)
	const state = useSelector((state) => state)
	const [timerStarted, setTimerStarted] = useState(false)

	useEffect(() => {
		if (bookSession) {
			dispatch(saveState(state))
		}
	}, [bookSession, state, dispatch])

	const [postConfirmAppointment, { data: apptconfirmation, loading }] =
		useLazyPostConfirmAppointmentQuery('')

	const [updateDeviceHistory] = useUpdateDeviceHistoryMutation()
	const deviceHistoryDetails = useSelector((state) => state.deviceHistory)
	const generalTests = useSelector((state) => state?.config?.generalTest)

	useEffect(() => {
		if (apptconfirmation) {
			if (deviceHistoryDetails?.deviceHistoryId) {
				updateDeviceHistory({
					id: deviceHistoryDetails?.deviceHistoryId,
					AppointmentBookingConfirmationNumber:
						apptconfirmation?.confirmationNo,
					StatusReason: DEVICE_HISTORY_STATUSES.Update,
				})
			}
			dispatch(
				updateAppointment({
					confirmationId: apptconfirmation?.confirmationNo,
					activityId: apptconfirmation?.activityID,
				})
			)
			dispatch(resetAppointmentApi())
			navigate(MCC_APPT_CONFIRMED)
		}
	}, [apptconfirmation])

	useEffect(() => {
		if (bookSession) {
			if (bookSession.errorCode === 0) {
				dispatch(updateAppointmentReserveId(bookSession.reserveID))
				if (selectedSpeciltyTest?.id) {
					createDeviceHistory({
						siteId: selectedLocation?.siteIdGuid,
						reservationId: bookSession.reserveID,
						appointmentsLotId: appointment?.appointment.appointmentSlotId,
						StatusReason: DEVICE_HISTORY_STATUSES.Create,
						SpecialtyTestId: selectedSpeciltyTest?.id,
					}).then((data) => {
						dispatch(setDeviceHistory(data?.data))
						dispatch(resetAppointmentBookingTimeLeft())
						if (source === 'D365') {
							dispatch(
								updateAppointment({
									status: APPOINTMENT_STATUS.CONFIRMED,
								})
							)

							postConfirmAppointment(
								{
									ReservationId: bookSession.reserveID,
									customerId: customerId,
									TestTypeId: appointment?.appointment.TestNameId
										? appointment?.appointment.TestTypeId
										: undefined,
									TestNameId: appointment?.appointment.TestNameId,
									bookingType: BookingType[0].bookingId, // Staff(CSR)
									slotId: appointment?.appointment.appointmentSlotId,
									apptType: APPOINTMENT_TYPE.Staff,
								} || {}
							)
						} else {
							createReservationTimer()
							navigate(MCC_CONFIRM_APPT)
						}
					})
				} else {
					dispatch(resetAppointmentBookingTimeLeft())
					if (source === 'D365') {
						dispatch(
							updateAppointment({
								status: APPOINTMENT_STATUS.CONFIRMED,
							})
						)

						postConfirmAppointment(
							{
								ReservationId: bookSession.reserveID,
								customerId: customerId,
								TestTypeId: appointment?.appointment.TestNameId
									? appointment?.appointment.TestTypeId
									: undefined,
								TestNameId: appointment?.appointment.TestNameId,
								bookingType: BookingType[0].bookingId, // Staff(CSR)
								slotId: appointment?.appointment.appointmentSlotId,
								apptType: APPOINTMENT_TYPE.Staff,
							} || {}
						)
					} else {
						createReservationTimer()
						navigate(MCC_CONFIRM_APPT)
					}
				}
			} else {
				setOpenToast(true)
				setAlertCode(250)
				setTimerStarted(false)
				setTimeSlotApiCalled(false)
			}
		}
	}, [bookSession])

	useEffect(() => {
		if (selectedSpeciltyTest) {
			setIsExpanded(true)
			let testPrepInformation = selectedSpeciltyTest.testPrepInformation
			if (testPrepInformation?.length > 0) {
				setTestPrepInformation([0].body)
			}
		}
		if (selectedEarliest) {
			setSelectedTime(moment(new Date(selectedEarliest)).format('h:mm A'))
			setSelectedDate(new Date(selectedEarliest))
		} else if (selectedFilterDate) {
			setSelectedDate(selectedFilterDate)
		} else {
			setSelectedDate(moment(new Date()).format('YYYY-MM-DD'))
		}
	}, [selectedEarliest, selectedSpeciltyTest, selectedFilterDate])

	const onClickHandlerExpand = (event, isExpanded) => {
		setIsExpanded(isExpanded)
	}

	useEffect(() => {
		dispatch(updateSelectedAppointmentDateTime({
			selectedDate: selectedDate?.toString(),
			selectedTime: selectedTime?.time
		}))
	}, [selectedTime])

	useEffect(() => {
		if (timeSlotApiCalled) {
			if (
				selectedDate &&
				selectedTime &&
				timeSlots?.length > 0 &&
				timeSlots?.find((slot) => slot.slotId === selectedTime?.slotId)
			) {
				let TestTypeId
				if (
					specialtyTests.find((test) => test.id === selectedSpeciltyTest?.id)
				) {
					TestTypeId = 'Specialty'
				} else {
					TestTypeId = 'Tolerance'
				}

				dispatch(
					updateAppointment({
						appointmentDate: selectedDate?.toString(),
						startTime: selectedTime?.time,
						appointmentSlotId: selectedTime?.slotId,
						appointmentType: selectedSpeciltyTest?.name || 'General Test',
						duration: selectedSpeciltyTest
							? selectedSpeciltyTest.duration
								? selectedSpeciltyTest.duration + 'min'
								: '60min'
							: generalTests?.duration
								? generalTests?.duration + 'min'
								: '30min',
						testId: selectedSpeciltyTest?.id,
						TestTypeId: TestTypeId,
						TestNameId: selectedSpeciltyTest?.id,
						startTimeBasedOnTimezone: selectedTime?.orgTime,
						endTimeBasedOnTimezone: calculateEndTime(
							selectedTime?.orgTime,
							selectedSpeciltyTest
								? selectedSpeciltyTest.duration
									? selectedSpeciltyTest.duration + 'min'
									: '60min'
								: generalTests?.duration
									? generalTests?.duration + 'min'
									: '30min'
						),
						mapAddress: appointment?.appointmentLocation?.address?.mapAddress,
					})
				)

				postBookSession(selectedTime?.slotId || '')
			} else {
				setSelectedTime()
				setOpenToast(true)
				setAlertCode(250)
				setTimerStarted(false)
				setTimeSlotApiCalled(false)
			}
		}
	}, [timeSlotApiCalled])

	const handleConFirmProvincePopup = () => {
		dispatch(resetMccCustomerApi())
		const apptProvince = getProvinceInitials(
			appointment?.appointmentLocation?.address?.province
		)
		const selectedProvinceLower = selectedProvince
		const apptProvinceLower = apptProvince

		const isSpecialCase =
			(selectedProvince.toLowerCase() === 'bc' &&
				(apptProvinceLower.toLowerCase() === 'on' ||
					apptProvinceLower.toLowerCase() === 'sk')) ||
			(apptProvinceLower.toLowerCase() === 'bc' &&
				(selectedProvinceLower.toLowerCase() === 'on' ||
					selectedProvinceLower.toLowerCase() === 'sk'))
		if (selectedProvinceLower === apptProvinceLower || !isSpecialCase) {
			onClickHandleSubmit(apptProvince)
			setProvinceDialogLocation(apptProvince)
		} else {
			setOpenDialog(true)
			setProvinceDialogLocation(apptProvince)
		}
	}

	const onClickHandlerBookAppointment = () => {
		if (source === 'D365') {
			onClickHandleSubmit()
		} else {
			dispatch(updateAppointmentState(APPOINTMENT_STATES[1]))
			handleConFirmProvincePopup()
		}
	}

	const onClickHandlerCloseDialog = (closeDialog) => {
		if (typeof closeDialog === 'string' && closeDialog === 'close') {
			setOpenDialog(false)
		} else {
			setOpenDialog(false)
			sessionStorage.setItem(
				'id4_url',
				provinceDialogLocation !== 'BC' ? ID4_BC : ID4_ON
			)
			sessionStorage.removeItem('prov')
			postTimeSlots({
				site_id: [selectedLocation?.siteIdGuid || selectedLocation.siteId],
				date: [moment(selectedDate).format('YYYY-MM-DD')],
				tests: {
					id: selectedSpeciltyTest?.id,
					type: selectedSpeciltyTest?.testType,
				},
			}).then(() => {
				setTimeSlotApiCalled(true)
			})
		}
	}

	const onClickHandleSubmit = (apptProvince) => {
		if (typeof apptProvince === 'string') {
			sessionStorage.setItem(
				'id4_url',
				apptProvince?.toLowerCase() === 'bc' ? ID4_BC : ID4_ON
			)
		} else {
			sessionStorage.setItem(
				'id4_url',
				provinceDialogLocation === 'BC' ? ID4_BC : ID4_ON
			)
		}
		setOpenDialog(false)
		sessionStorage.removeItem('prov')
		postTimeSlots({
			site_id: [selectedLocation?.siteIdGuid || selectedLocation.siteId],
			date: [moment(selectedDate).format('YYYY-MM-DD')],
			tests: {
				id: selectedSpeciltyTest?.id,
				type: selectedSpeciltyTest?.testType,
			},
		}).then(() => {
			setTimeSlotApiCalled(true)
		})
	}

	const onChageHandlerSelectedDate = (date) => {
		setSelectedDate(date)
		setSelectedTime()
		setTimeOpen(true)
	}

	const [disabledDates, setDisabledDates] = useState([])

	const disableDates = (date) => {
		let isDisabled = false

		//157148 - servicedisruption should remain open.

		if (selectedLocation?.tempClosure) {
			isDisabled = selectedLocation.tempClosure.some((sd) => {
				return moment(date).isBetween(sd.closureStartDate, sd.closureEndDate)
			})
		}

		return isDisabled
	}

	useEffect(() => {
		const selectedTimeZone = microsoftSupportedTimezones.find(
			(zone) => zone.name === selectedLocation?.address?.timeZone
		)
		const today = new Date().toLocaleString('en-US', {
			timeZone: selectedTimeZone?.browserTimezone || 'America/New_York',
		})
		if (!disableDates(today) && selectedDate) {
			postTimeSlots({
				site_id: [selectedLocation?.siteIdGuid || selectedLocation.siteId],
				date: [moment(selectedDate).format('YYYY-MM-DD')],
				tests: {
					id: selectedSpeciltyTest?.id,
					type: selectedSpeciltyTest?.testType,
				},
			})
			setTimeOpen(true)
		}
	}, [selectedDate, selectedLocation])

	const handleGetTimeSlots = () => {
		const today = new Date()
		if (!disableDates(today) && selectedDate) {
			postTimeSlots({
				site_id: [selectedLocation?.siteIdGuid || selectedLocation.siteId],
				date: [moment(selectedDate).format('YYYY-MM-DD')],
				tests: {
					id: selectedSpeciltyTest?.id,
					type: selectedSpeciltyTest?.testType,
				},
			})
			setTimeOpen(true)
		}
	}

	useEffect(() => {
		if (selectedLocation) {
			const selectedTimeZone = microsoftSupportedTimezones.find(
				(zone) => zone.name === selectedLocation?.address?.timeZone
			)
			const today = new Date().toLocaleString('en-US', {
				timeZone: selectedTimeZone?.browserTimezone || 'America/New_York',
			})
			let dates = []
			if (selectedLocation.statClosure) {
				dates = [
					...dates,
					...selectedLocation.statClosure.filter(
						(closure) =>
							calculateDurationInDays(closure.closureStartDate, today) > 0
					),
				]
			}

			setDisabledDates([...dates])
			setTestPrepInformation(
				selectedLocation.standardTestInstructions || Strings.STANDARD_PREP_BODY
			)
		}
	}, [selectedLocation])

	useEffect(() => {
		const appointmentDate = appointmentDetails?.appointmentDate
		if (appointmentDate && !selectedDate) {
			setSelectedDate(appointmentDate)
			setFromDiscardButton(true)
			postTimeSlots({
				site_id: [selectedLocation?.siteIdGuid || selectedLocation.siteId],
				date: [moment(new Date(appointmentDate)).format('YYYY-MM-DD')],
				tests: {
					id: selectedSpeciltyTest?.id,
					type: selectedSpeciltyTest?.testType,
				},
			})
		}

		if (appointmentDate && timeSlots?.length > 0 && !selectedDate) {
			const appointmentTime = timeSlots.find(
				(hour) => hour?.time === appointmentDetails?.startTime
			)
			setSelectedTime(appointmentTime)
			setTimeOpen(false)
		}
	}, [appointmentDetails?.appointmentDate, timeSlots])

	useEffect(() => {
		dispatch(resetAppointReserveId())
		dispatch(resetAppointmentApi())
		dispatch(resetSelectedAppointmentDateTime())
		if (getItemFromBrowserStorage('user')) {
			let storageUser = JSON.parse(getItemFromBrowserStorage('user'))
			if (storageUser?.selectedAdhocUser) {
				delete storageUser['selectedAdhocUser']
			}
			setItemFromBrowserStorage('user', JSON.stringify(storageUser))
		}
	}, [])

	const monthChange = () => {
		if (!fromDiscardButton && !selectedEarliest && !selectedFilterDate) {
			setSelectedDate('')
		} else {
			setFromDiscardButton(false)
		}
		// Handle the month change as needed
	}

	useEffect(() => {
		if (selectedTime && !selectedTime?.slotId && timeSlots) {
			setSelectedTime(timeSlots.find((slot) => slot?.time === selectedTime))
		}
	}, [selectedTime, timeSlots])

	return (
		<Stack>
			<AppointmentHints
				selectedLocation={selectedLocation}
				selectedSpeciltyTest={selectedSpeciltyTest}
				generalTests={generalTests}
			/>

			<SessionExpire
				applicationSettings={applicationSettings}
				selectedTime={selectedTime}
				setSelectedTime={setSelectedTime}
				setOpenToast={setOpenToast}
				setAlertCode={setAlertCode}
				setOpenDialog={setOpenDialog}
				timerStarted={timerStarted}
				setTimerStarted={setTimerStarted}
			/>

			<Grid
				container
				direction="column"
				sx={{
					backgroundColor: 'info.dark',
					gap: '24px',
					overflow: 'scroll',
				}}
			>
				<Grid
					sx={{
						padding: '1.5rem 1.5rem 0.9rem',
					}}
				>
					<InfoAccordion
						infoAccordionId="standard-prep"
						infoAccordionHeader={Strings.STANDARD_PREP_HEADER}
						infoAccordionHeaderIcon={<User fillColor="#13499F" />}
						expandIcon={
							!isExpanded ? (
								<Plus fillColor="#13499F" />
							) : (
								<Minus fillColor="#13499F" />
							)
						}
						onChangeHandler={onClickHandlerExpand}
						isExpanded={isExpanded}
					>
						<div
							dangerouslySetInnerHTML={{ __html: testPrepInformation || '' }}
							tabIndex={0}
						></div>
					</InfoAccordion>
				</Grid>

				<Grid
					container
					sx={{
						padding: '0px 40px',
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
						gap: {
							xs: '24px',
							md: '0',
						},
						justifyContent: {
							xs: 'inherit',
							md: 'space-between',
						},
					}}
				>
					<Grid
						item
						xs={6}
						sx={{
							gap: '12px',
							display: 'flex',
							flexDirection: 'column',
							maxWidth: {
								xs: '100%',
								md: '50%',
							},
						}}
					>
						<IconGrid
							sx={{ gap: '10px', alignItems: 'center' }}
							muiIcon={<ConfirmCalendar />}
						>
							<Text.Bold
								variant="paragraphBold1"
								i18nKey="LBL_SELECT_PREF_DATE"
							/>
						</IconGrid>

						<DatePicker
							selectedDate={selectedDate}
							timeslot={timeSlots}
							onChange={(date) => {
								postTimeSlots({
									site_id: [
										selectedLocation?.siteIdGuid || selectedLocation.siteId,
									],
									date: [moment(new Date(date)).format('YYYY-MM-DD')],
									tests: {
										id: selectedSpeciltyTest?.id,
										type: selectedSpeciltyTest?.testType,
									},
								})
								onChageHandlerSelectedDate(date)
							}}
							shouldDisableDate={disableDates}
							onmonthChange={monthChange}
						/>
					</Grid>

					<Grid
						item
						xs={6}
						sx={{
							gap: '.8rem',
							display: 'flex',
							flexDirection: 'column',
							maxWidth: {
								xs: '100%',
								md: '50%',
							},
						}}
					>
						<IconGrid
							sx={{ gap: '10px', marginLeft: { xs: '0', md: '3.5rem' } }}
							muiIcon={<TimerIcon />}
						>
							<Text.Bold
								variant="paragraphBold1"
								i18nKey="LBL_SELECT_TIME_SLOT"
							/>
						</IconGrid>

						<Stack
							sx={{
								gap: '8px',
								paddingLeft: { xs: '0', md: '3.5rem' },
								marginTop: '13px',
							}}
						>
							<InputLabel
								id="time-select-label"
								htmlFor="time-select"
								sx={{ color: '#000000', paddingLeft: '0.5rem' }}
							>
								<Text i18nKey="LBL_TIME_SLOT" />
							</InputLabel>
							<AppointmentTimeSlot
								selectedDate={selectedDate}
								selectedTime={selectedTime}
								setSelectedTime={setSelectedTime}
								setTimeOpen={setTimeOpen}
								source={source}
								timeOpen={timeOpen}
								timeSlots={timeSlots}
							/>
							<Text
								variant="paragraph3"
								i18nKey="MSG_LOCATION_TIME_ZONE"
								sx={{
									color: '#595959',
									width: {
										md: '250px',
										xs: '100%',
									},
								}}
							/>
						</Stack>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems="center"
					justifyContent="center"
					sx={{ padding: '24px 10px' }}
				>
					<RoundedButton
						{...testAttr('next-btn')}
						onClick={onClickHandlerBookAppointment}
						disabled={!selectedDate || !selectedTime}
						i18nKey={'BTN_NEXT'}
						sx={{
							width: {
								xs: '250px',
								md: '13.125rem',
							},
						}}
					/>
				</Grid>
			</Grid>

			<ConfirmDialog
				confirmDialogId="confirm-province"
				openDialog={openDialog}
				onClickHandlerClose={onClickHandlerCloseDialog}
				onclickHandlerSubmit={onClickHandleSubmit}
				confirmDialogHeader={
					provinceDialogLocation === 'BC'
						? Strings.CONFIRM_BC_PROVINCE_TITLE
						: Strings.CONFIRM_ON_PROVINCE_TITLE
				}
			>
				<Stack>
					<Typography variant="paragraph2">
						{provinceDialogLocation === 'BC'
							? Strings.MESSAGES.CONFIRM_BC_PROVINCE
							: Strings.MESSAGES.CONFIRM_ON_PROVINCE}
					</Typography>
					<Typography
						variant="paragraph2"
						sx={{
							marginTop: '1rem',
							fontWeight: '600',
						}}
					>
						{provinceDialogLocation === 'BC'
							? Strings.MESSAGES.CONFIRM_PROVINCE_BC_QUES
							: Strings.MESSAGES.CONFIRM_PROVINCE_ON_QUES}
					</Typography>
				</Stack>
			</ConfirmDialog>

			<Toast
				open={openToast}
				handleClose={() => setOpenToast(false)}
				alertCode={alertCode}
			/>
		</Stack>
	)
}

export default Appointment
