import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isGoogleApiLoaded: false,
	map: null,
	mapObj: null,
	loadTrigger: !process.env.REACT_APP_GOOGLE_API_OPTIMIZATION || false,
}

export const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setApiState: (state, action) => {
			state.isGoogleApiLoaded = action.payload
		},
		setLoadState: (state, action) => {
			state.loadTrigger = action.payload
		},

		setMapObj: (state, action) => {
			state.mapObj = action.payload
		  }
	}
})

export const { setApiState, loadMap, setMapObj, setLoadState } = mapSlice.actions
export default mapSlice.reducer
