import { createApi } from '@reduxjs/toolkit/query/react'
import {
	LOCATIONS_PATH,
	GEOCODE_PATH
} from './url'
import moment from 'moment'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'

export function modifyAppendData(data) {
	let appendData = {}
	if (data?.services?.length > 0) {
		appendData.services = [...data.services]
	}
	if (data?.specialityTests?.name) {
		appendData.test = {
			"id": data.specialityTests?.id,
			"type": data.specialityTests?.testType
		}
	}
	if(!appendData?.test){
		appendData.test = {
			"id": data?.applicationSettings?.generalspecialtytestguid,
			"type": '1'
		}
	}
	if (data?.date) {
		appendData.date = moment(data.date).format('YYYY-MM-DD')
	}
	return appendData
}

export const transformResponse = (data, meta, arg) => {
	const servicesLength = arg.services?.length
	const testsLength = arg.specialityTests?.name?.length || 0
	const isDateSelected = !!arg.date
	data.alertCode = 0
	if (data.locations?.length > 0) {
		if (servicesLength > 0 || testsLength > 0 || isDateSelected) {
			data.alertCode = 500
		}
		data.siteIds = data.locations.map(location => location.siteIdGuid)
	} else {
		if (servicesLength > 0 && testsLength === 0) {
			data.alertCode = 200
		} else if (testsLength > 0) {
			data.alertCode = 400
		} else {
			data.alertCode = 300
		}
	}
	return data
};

export const locationApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'locations',
	endpoints: (build) => ({
		postLocations: build.mutation({
			query: (body) => {
				return {
					url: `${LOCATIONS_PATH}`,
					method: 'POST',
					body: {
						address: [body.lat, body.long],
						limit: 10,
						offset: 1,
						...modifyAppendData(body)
					},
				}
			},
			transformResponse,
			transformErrorResponse
		}),
		getGeocode: build.query({
			query: (address) => ({
				url: `${GEOCODE_PATH}?address=${address}`,
				method: 'GET',
			}),
		}),
	}),
})

export const { usePostLocationsMutation, useLazyGetGeocodeQuery } = locationApi
