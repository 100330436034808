import { Chip, Grid, Stack, Typography } from "@mui/material";
import Text from "components/Text";
import { ReactComponent as LocationPin } from 'assets/LocationPin.svg'
import { ReactComponent as Results } from 'assets/Results.svg'

import IconGrid from "components/data-display/IconGrid/IconGrid";
import { Strings } from "utils/Strings";
import { LeftBorderGrid } from "components/CommonComponents";

const AppointmentHints = ({ selectedLocation, selectedSpeciltyTest, generalTests }) => {
    return (
        <Grid
            container
            sx={{
                backgroundColor: 'info.bg',
                padding: '0px 2rem 2rem',
                flexDirection: {
                    xs: "column",
                    md: "row"
                },
                gap:{
                    xs: "24px",
                },
                justifyContent: {
                    xs: 'inherit',
                    md: 'space-between'
                }
            }}
        >
            <Grid
                item
            >
                <Stack>
                    <IconGrid mb={1}
                        muiIcon={<LocationPin stroke="black" />}>
                        <Text.Bold variant="paragraphBold1"
                            i18nKey='LBL_AT_THIS_LOCATION' />
                    </IconGrid>

                    <LeftBorderGrid
                        item
                    >
                        <Typography variant="paragraph2" sx={{ fontWeight: '600' }}>
                            {selectedLocation.siteName}
                        </Typography>

                        <Typography variant="paragraph3">
                            {selectedLocation.address?.address1},
                            {selectedLocation.address?.address2},
                            {selectedLocation.address?.city},
                            {selectedLocation.address?.province}
                        </Typography>
                    </LeftBorderGrid>
                </Stack>
            </Grid>

            <Grid
                item
            >
                <Stack>
                    <IconGrid mb={1}
                        muiIcon={<Results />}
                    >
                        <Text.Bold variant="paragraphBold1"
                            i18nKey={selectedSpeciltyTest ?
                                'LBL_SPECIALITY_TEST' :
                                'LBL_GENERAL_TEST'}
                        />
                    </IconGrid>

                    <LeftBorderGrid
                        container
                    >
                        <Typography variant="paragraph2" sx={{ fontWeight: '600' }}>
                            {Strings.APPOINTMENTBOOKINGS_APPOINTMENTDURATION}
                            {selectedSpeciltyTest
                                ? (selectedSpeciltyTest?.duration ? 
                                    selectedSpeciltyTest?.duration +"min" : '60min')
                                : (generalTests?.duration
								? generalTests?.duration + 'min'
								: '30min')}
                        </Typography>
                        {selectedSpeciltyTest && (
                            <Chip
                                label={selectedSpeciltyTest.name}
                                sx={{
                                    width: 'fit-content',
                                    height: '30px',
                                    borderRadius: '0.2rem',
                                    border: 'unset',
                                    margin: '0.5rem',
                                    backgroundColor: 'text.light',
                                    color: 'text.dark',
                                }}
                            />
                        )}
                    </LeftBorderGrid>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default AppointmentHints;
