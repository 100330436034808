import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import moment from 'moment/moment'

import {
	Grid,
	Typography,
	RadioGroup,
	Radio,
	MenuItem,
	FormControlLabel,
	Box,
	Stack,
	Select,
	IconButton,
	Tooltip,
	Checkbox,
	InputLabel,
	MenuList,
	FormControl,
	FormLabel,
	styled,
	tooltipClasses,
} from '@mui/material'

import AppointmentLeftPanel from './AppointmentLeftPanel'
import CustomAppointmentToolTip from './CustomAppointmentToolTip'
import ConfirmDialog from 'components/ConfirmDialog'
import RoundedButton from 'components/buttons/RoundedButton'
import RoundedTextField from 'components/input/RoundedTextField'
import Text from 'components/Text'
import Toast from 'components/feedback/toast/Toast'

import { HOME, MCC_APPT_CONFIRMED } from 'utils/constants/routes-constants'
import { useAuth } from 'hooks/useAuth'

import { Strings } from 'utils/Strings'
import strings from 'locales/en-US.json'

import Plus from 'assets/Plus'
import TrashIcon from 'assets/TrashIcon'

import { ReactComponent as ArrowDown } from 'assets/FilterIcons/DropDownArrow.svg'
import { ReactComponent as FilledCheckbox } from 'assets/FilterIcons/FilledCheckbox.svg'
import { ReactComponent as InformationIcon } from 'assets/information.svg'
import { ReactComponent as InformationFilledIcon } from 'assets/informationFilled.svg'
import { ReactComponent as TimerIcon } from 'assets/Timer-Icon.svg'
import { ReactComponent as UncheckedCheckbox } from 'assets/FilterIcons/UncheckedCheckbox.svg'

import { resetFilters } from 'features/filters/filterSlice'
import {
	APPOINTMENT_STATES,
	APPOINTMENT_STATUS,
	resetAppointReserveId,
	resetAppointment,
	resetAppointmentTime,
	updateAppointment,
	updateAppointmentDiscardedStatus,
	updateAppointmentState,
	resetSelectedAppointmentDateTime
} from './appointmentSlice'

import useCountdownTimer from 'hooks/useCountDownTimer'

import { useLazyPostConfirmAppointmentQuery } from 'store/services/appointmentApi'
import {
	useLazyCreateCustomerQuery,
	usePostCustomerDataMutation,
} from 'store/services/customerApi'
import {
	resetBookSessionApi,
	useDeleteReservationMutation,
} from 'store/services/reservationApi'
import {
	resetExtendSessionApi,
	useLazyPostExtendSessionQuery,
} from 'store/services/extendReservationApi'

import { useLazyGetCustomerDataQuery } from 'store/services/customerApi'
import { useSyncMccCustomerDataMutation } from 'store/services/syncCustomerAPI'
import { getExtendTimeDuration } from 'utils/dateTime.utils'
import {
	ERRORS,
	getAuthToken,
	getItemFromBrowserStorage,
	removeAuthToken,
	removeItemFromBrowserStorage,
	setItemFromBrowserStorage,
} from 'utils/common.utils'
import { TZ_FORMAT } from 'utils/constants/time-constants'
import { useUpdateDeviceHistoryMutation } from 'store/services/deviceApi'
import {
	APPOINTMENT_TYPE,
	BookingType,
	DEVICE_HISTORY_STATUSES,
} from 'utils/constants/common.constants'
import { saveState } from 'store/reducers/appStateReducer'
import { resetMccCustomerApi } from 'store/services/mccCustomerApi'
import { STRINGS } from 'utils/constants/string.constants'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'
import CustomizeTooltip from 'components/ToolTip/ToolTip'
import { updateCustomerDetails } from 'store/reducers/customerDetailsReducer'

const CustomRadio = styled(Radio)(() => ({
	'&:focus-visible': {
		outline: '2px solid #000000',
		boxShadow: 'none',
	},
	'&.Mui-checked:focus-visible': {
		outline: '2px solid #000000',
	},
	'&.Mui-focusVisible': {
		outline: '2px solid #000000',
		boxShadow: 'none',
	},
}))

/* istanbul ignore next */
const ConfirmAppointment = ({ ...props }) => {
	const [getCustomerData, { data: customerData }] =
		useLazyGetCustomerDataQuery('')
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const preferredCommunicationSMS = '178550003'
	const preferredCommunicationEmail = '178550000'

	const { user } = useAuth()
	const applicationSettings = useSelector(
		(state) => state.config?.applicationSettings
	)
	const accessToken = useSelector((state) => state.auth?.token)
	const idToken = useSelector((state) => state.auth?.idToken)
	const authToken = getAuthToken()

	const appointment = useSelector((state) => state.appointment.appointment)
	const appointmentDetails = useSelector((state) => state.appointment)
	const deviceHistoryDetails = useSelector((state) => state.deviceHistory)

	const selectedLocation = useSelector(
		(state) => state.appointment.appointmentLocation
	)
	const appointmentReserveId = useSelector(
		(state) => state.appointment.appointmentReserveId
	)
	const [postCustomerData] = usePostCustomerDataMutation()
	const [syncMccCustomerData, { data: mccPwtSyncUp }] =
		useSyncMccCustomerDataMutation()
	const [updateDeviceHistory] = useUpdateDeviceHistoryMutation()

	const [createCustomer, { data: customerCreated }] =
		useLazyCreateCustomerQuery()
	const [value, setValue] = useState(Strings.CONFIRM_APPT_YES)
	const [customerId, setCustomerId] = useState('')
	const [name, setName] = useState({ first: '', last: '' })
	const [userType, setUserType] = useState(Strings.SELECT_A_PERSON)
	const [users, setUsers] = useState([
		user?.firstName + ' ' + (user?.lastName || ''),
	])
	const [adhocUserDuplicateNameError, setAdhocUserDuplicateNameError] =
		useState(false)
	const [adhocUsers, setAdhocUsers] = useState([])
	const [adhocUserID, setAdhocUserID] = useState('')
	const [adhocStatus, setAdhocStatus] = useState(false)
	const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
	const [formattedTime, resetTimer] = useCountdownTimer(
		appointmentDetails?.appointmentBookingTimeLeft.length > 0
			? appointmentDetails?.appointmentBookingTimeLeft
			: applicationSettings?.reservationTimerExtensionDuration
	)

	const [isTooltipOpen, setIsTooltipOpen] = useState(false)
	const [isCheckboxTooltipOpen, setIsCheckboxTooltipOpen] = useState(false)
	const [isCheckboxTextTooltipOpen, setIsCheckboxTextTooltipOpen] = useState(false)
	const [isEmailSelected, setIsEmailSelected] = useState(false)
	const [openToast, setOpenToast] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [sessionDialog, setSessionDialog] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [isPhoneNull, setCheckPhone] = useState()
	const [errorTypes, setErrorTypes] = useState([])
	const [selectedUser, setSelectedUser] = useState({
		firstName: '',
		lastName: '',
	})
	const [nameError, setNameError] = useState(false)
	const [mccpwtSyncApiCalled, setMccpwtSyncApiCalled] = useState(false)
	const selectedProvince = useSelector((state) => state.selectedProvince)

	const handleMenuItemClick = (selectedUser) => {
		if (!selectedUser.phone) {
			setCheckPhone(true)
		} else {
			setCheckPhone(false)
		}
		setAdhocUserID(selectedUser.id)
		setUserType(Strings.SELECT_A_PERSON)
		setSelectedUser(selectedUser)
		setPhoneNumber(selectedUser.phone)
		setEmailAddress(selectedUser.email)
		if (selectedUser?.emailConsent) {
			setIsEmailSelected(true)
		} else {
			setIsEmailSelected(false)
		}
	}

	const onChangeHandlerMakeAppt = (event) => {
		if (event.target.value === Strings.CONFIRM_APPT_YES) {
			setAdhocStatus(false)
			setPhoneNumber(user.phone)
			setEmailAddress(user.email)
			setAdhocUserID('')
			setSelectedUser({
				firstName: '',
				lastName: '',
			})
			if (customerData?.emailConsent) {
				setIsEmailSelected(true)
			} else {
				setIsEmailSelected(false)
			}
		}

		if (event.target.value === Strings.CONFIRM_APPT_NO) {
			setAdhocStatus(true)
			setPhoneNumber('')
			setEmailAddress('')
			setIsEmailSelected(true)
		}
		setName({ first: '', last: '' })
		setNameError(false)
		setValue(event.target.value)
	}

	useEffect(() => {
		const id4ProvinceUrl = sessionStorage.getItem('id4_url')
		let selectedProvince
		if (id4ProvinceUrl && (id4ProvinceUrl?.includes('-bc-') || id4ProvinceUrl?.includes('bc-api'))) {
			selectedProvince = 'bc'
		} else {
			selectedProvince = 'on'
		}
		sessionStorage.setItem('selectedProvince', selectedProvince?.toLowerCase())
		if (
			accessToken &&
			selectedProvince &&
			!mccPwtSyncUp &&
			!mccpwtSyncApiCalled
		) {
			setMccpwtSyncApiCalled(true)
			syncMccCustomerData({
				AccessToken: accessToken,
				SyncStatus: false,
				Scope: selectedProvince,
			}).then((res) => {
				console.log('Data Sync Successfully', res)
			})
		}
	}, [accessToken])

	useEffect(() => {
		if (customerData) {
			setAdhocUsers(customerData?.relatedContacts)
		}
	}, [customerData])

	useEffect(() => {
		if (mccPwtSyncUp) {
			const get_customer = getCustomerData(user.accountId)
			get_customer
				.then((datalist) => {
					dispatch(updateCustomerDetails(datalist?.data));
					setCustomerId(datalist?.data.id)
					// Assuming we have a function called setAdhocUsers to the set state
					setPhoneNumber(datalist?.data.phone)
					setEmailAddress(datalist?.data.email)
					setAdhocUsers(datalist?.data?.relatedContacts)
					if (datalist?.data?.emailConsent) {
						setIsEmailSelected(true)
					}
				})
				.catch((error) => {
					// Handle any errors that occur during the fetch
					console.error('Error fetching customer data:', error)
				})
		}
	}, [mccPwtSyncUp])

	const handleChangeName = (key, value, error) => {
		if (error) {
			setNameError(true)
		} else {
			setNameError(false)
		}
		setAdhocStatus(true)
		setName({
			...name,
			[key]: value,
		})
	}

	const handleCheckDuplicateName = () => {
		return adhocUsers?.find(
			(adhocUser) =>
				adhocUser.firstName === name.first && adhocUser.lastName === name.last
		)
	}

	useEffect(() => {
		setAdhocUserDuplicateNameError(handleCheckDuplicateName() ? true : false)
	}, [name])

	const [
		postConfirmAppointment,
		{ data: apptconfirmation, loading, error: apptBookingError },
	] = useLazyPostConfirmAppointmentQuery('')

	const [postExtendSession, { data: extendTime }] =
		useLazyPostExtendSessionQuery({})

	const [prevTime, setPrevTime] = useState(0)

	useEffect(() => {
		if (extendTime?.expAt) {
			const extendTimeDuration = getExtendTimeDuration(
				extendTime?.expAt,
				prevTime
			)
			resetTimer(
				extendTimeDuration !== 2
					? applicationSettings?.reservationTimerExtensionDuration
					: extendTimeDuration
			)
			setSessionDialog(false)
			setPrevTime(extendTime?.expAt)
			dispatch(resetExtendSessionApi())
		}
	}, [extendTime, resetTimer])

	useEffect(() => {
		if (
			apptBookingError &&
			apptBookingError?.data?.error ===
				'reservation Id is null or does not exist.please enter a valid reservation id'
		) {
			apptSessionExpired()
		}
	}, [apptBookingError])

	useEffect(() => {
		if (apptconfirmation) {
			if (deviceHistoryDetails?.deviceHistoryId) {
				updateDeviceHistory({
					id: deviceHistoryDetails?.deviceHistoryId,
					AppointmentBookingConfirmationNumber:
						apptconfirmation?.confirmationNo,
					StatusReason: DEVICE_HISTORY_STATUSES.Update,
				})
			}
			dispatch(
				updateAppointment({
					status: APPOINTMENT_STATUS.CONFIRMED,
					confirmationId: apptconfirmation?.confirmationNo,
					activityId: apptconfirmation?.activityID,
				})
			)
			dispatch(updateAppointmentState(APPOINTMENT_STATES[3]))
			navigate(MCC_APPT_CONFIRMED, { replace: true })
		}
	}, [apptconfirmation, dispatch, navigate])

	useEffect(() => {
		if (customerCreated) {
			handleConfirmAppointment()
		}
	}, [customerCreated])

	const handleConfirmAppointment = (userId) => {
		const customerData = {
			customerId: customerCreated?.contactGuid
				? customerCreated?.contactGuid
				: adhocUserID
					? adhocUserID
					: customerId,
			operationType: 'UPDATE',
			customerDetails: {},
		}
		customerData.customerDetails.FirstName = customerCreated?.contactGuid
			? name.first
			: adhocStatus
				? selectedUser.firstName || name.first
				: user.firstName
		customerData.customerDetails.LastName = customerCreated?.contactGuid
			? name.last
			: adhocStatus
				? selectedUser.lastName || name.last
				: user.lastName
		customerData.customerDetails.Phone = phoneNumber
		if (isEmailSelected) {
			customerData.customerDetails.Email = emailAddress
			customerData.customerDetails.emailConsent = true
		} else {
			customerData.customerDetails.emailConsent = false
		}

		if (adhocStatus) {
			if (getItemFromBrowserStorage('user')) {
				let storageUser = JSON.parse(getItemFromBrowserStorage('user'))
				let selectedAdhocUser = {
					firstName: customerCreated?.contactGuid
						? name.first
						: selectedUser.firstName,
					lastName: customerCreated?.contactGuid
						? name.last
						: selectedUser.lastName,
				}
				storageUser['selectedAdhocUser'] = selectedAdhocUser
				setItemFromBrowserStorage('user', JSON.stringify(storageUser))
			}
		}

		postCustomerData(customerData)
		postConfirmAppointment(
			{
				ReservationId: appointmentReserveId,
				customerId:
					value === Strings.CONFIRM_APPT_NO && name.first.length > 0
						? customerCreated?.contactGuid
						: adhocStatus
							? adhocUserID
							: customerId,
				TestTypeId: appointment.TestNameId ? appointment.TestTypeId : undefined,
				TestNameId: appointment.TestNameId,
				apptType:
					value === Strings.CONFIRM_APPT_NO
						? APPOINTMENT_TYPE.AdhocCustomer
						: APPOINTMENT_TYPE.PrimaryCustomer,
				bookingType: BookingType[2].bookingId, // Web
			} || {}
		)
	}

	const handleClickAppointment = () => {
		const customerData = {
			customerId: user.id,
			operationType: 'CREATE',
		}
		if (value === Strings.CONFIRM_APPT_NO && name.first.length > 0) {
			const customerData = {
				ParentId: customerId,
			}

			customerData.firstName = name.first
			customerData.lastName = name.last
			customerData.phone = phoneNumber

			if (isEmailSelected) {
				customerData.email = emailAddress
				// customerData.notificationpref = preferredCommunicationEmail
			} else {
				// customerData.notificationpref = preferredCommunicationSMS
			}

			createCustomer(customerData)
		} else {
			handleConfirmAppointment(user.id)
		}
		dispatch(resetSelectedAppointmentDateTime())
	}

	const handleClickBack = () => {
		setOpenDialog(true)
	}

	const checkIfDisabled = () => {
		const isPhoneNumberMissing =
			phoneNumber === null || phoneNumber?.length === 0
		const isEmailAddressMissing = isEmailSelected && emailAddress?.length === 0
		const isAnyError = isEmailSelected
			? errorTypes.length > 0
			: errorTypes.includes('phoneNumber')
		const isNameMissing =
			name.first?.length === 0 || name.last?.length === 0 || nameError
		if (value === Strings.CONFIRM_APPT_NO) {
			if (userType === Strings.ADD_A_NEW_PERSON) {
				return (
					isNameMissing ||
					isPhoneNumberMissing ||
					isEmailAddressMissing ||
					isAnyError ||
					adhocUserDuplicateNameError
				)
			} else {
				return (
					!selectedUser ||
					isPhoneNumberMissing ||
					isEmailAddressMissing ||
					isAnyError
				)
			}
		}

		return isPhoneNumberMissing || isEmailAddressMissing || isAnyError
	}

	const handleCommunicationPrefreences = (type, value, error) => {
		if (error) {
			!errorTypes.includes(type) && setErrorTypes([...errorTypes, type])
		} else {
			errorTypes.includes(type) &&
				setErrorTypes(errorTypes.filter((inputType) => inputType !== type))
		}

		if (type === 'email') {
			setEmailAddress(value)
		} else {
			setPhoneNumber(value)
		}
	}

	const [deleteReservation] = useDeleteReservationMutation()
	const state = useSelector((state) => state)
	const hasLogoutInitiated = useRef(false)

	useEffect(() => {
		if (
			state?.appointment?.appointmentReserveId === '' &&
			!hasLogoutInitiated?.current
			&& apptDiscard != true
		) {
			dispatch(saveState(state))
			hasLogoutInitiated.current = true
			window.location.href = MCCRedirectionUrls.Logout.replace(
				'{ID_TOKEN}',
				idToken
			).replace('{LOGOUT_REDIRECTION_URL}',process.env.REACT_APP_BASE_URL_PROD)
		}
	}, [state])

	const [apptDiscard, setApptDiscard] = useState(false);

	const apptDiscarded = () => {
		setApptDiscard(true);
		deleteReservation(appointmentReserveId).then(async () => {
			if (deviceHistoryDetails?.deviceHistoryId) {
				updateDeviceHistory({
					id: deviceHistoryDetails?.deviceHistoryId,
					StatusReason: DEVICE_HISTORY_STATUSES.Delete,
				})
			}
			await Promise.all([
				dispatch(updateAppointmentState(APPOINTMENT_STATES[4])),
				dispatch(resetAppointmentTime()),
				dispatch(resetAppointReserveId()),
				dispatch(resetSelectedAppointmentDateTime())
			])
			setOpenDialog(false)
			window.location.href = MCCRedirectionUrls.Authenticated_Location_Finder.replace(
				'selectedProvince',
				selectedProvince?.toLowerCase()
			)
		})
	}

	const apptSessionExpired = () => {
		deleteReservation(appointmentReserveId).then(async () => {
			setSessionDialog(false)
			await Promise.all([
				dispatch(updateAppointmentState(APPOINTMENT_STATES[4])),
				dispatch(updateAppointmentDiscardedStatus(true)),
				dispatch(resetAppointReserveId()),
				dispatch(resetAppointment()),
				dispatch(resetFilters()),
				dispatch(resetSelectedAppointmentDateTime())
			])
			removeAuthToken()
			removeItemFromBrowserStorage('user')
		})
	}

	useEffect(() => {
		sessionStorage.setItem('appointmentBookingTimeLeft', formattedTime)
		if (formattedTime === '0:30') {
			setSessionDialog(true)
		}

		if (formattedTime === '0:00') {
			apptSessionExpired()
		}
	}, [formattedTime])

	const handleExtendSession = () => {
		postExtendSession(appointmentReserveId || '')
	}

	const handleBeforeUnload = () => {
		sessionStorage.setItem('appointment', JSON.stringify(appointmentDetails))
	}

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload)
		dispatch(resetBookSessionApi())
		dispatch(resetMccCustomerApi())
		localStorage.removeItem('appState')
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			sessionStorage.removeItem('appointmentBookingTimeLeft')
		}
	}, [])

	useEffect(() => {
		if (accessToken === null && appointmentReserveId && !authToken) {
			apptSessionExpired()
		}
	}, [accessToken])

	useEffect(() => {
		handleExtendSession()
	}, [])

	return (
		<Grid
			container
			sx={{
				flexDirection: {
					xs: 'column',
					md: 'row',
				},
				flexWrap: 'nowrap',
			}}
			{...props}
		>
			<AppointmentLeftPanel
				actions={{
					handleClickBack,
				}}
				formattedTime={formattedTime}
			/>

			<Grid
				id="user-box"
				sx={{
					flexGrow: 1,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Grid
					container
					flexDirection="column"
					sx={{
						paddingTop: '1rem',
						paddingLeft: {
							xs: '1rem',
							md: '14.375rem',
						},
					}}
				>
					<Typography
						variant="h1"
						sx={{
							fontSize: '36px',
							marginTop: {
								xs: '0',
								md: '85px',
							},
						}}
					>
						Hi{' '}
						{user ? (user.firstName || '') + ' ' + (user.lastName || '') : ''},
					</Typography>

					<Typography
						sx={{
							fontWeight: '600',
							marginTop: {
								xs: 2,
								md: 4,
							},
							marginBotom: 3,
							fontSize: {
								xs: '1.25rem',
								md: '1.5rem',
							},
						}}
					>
						{Strings.CONFIRM_APPT_QUERY}
					</Typography>
					<FormControl>
						<RadioGroup
							aria-labelledby="confirm-appointment-radio-buttons-group-label"
							name="radio-buttons-group"
							value={value}
							defaultValue={Strings.CONFIRM_APPT_YES}
							onChange={onChangeHandlerMakeAppt}
							role="radiogroup"
						>
							<FormControlLabel
								sx={{
									height: '30px',
									paddingLeft: '0px',
									paddingTop: '16px',
									textWrap: 'wrap',
								}}
								value={Strings.CONFIRM_APPT_YES}
								control={
									<CustomRadio
										disableRipple
										inputProps={{
											'aria-checked': value === Strings.CONFIRM_APPT_YES,
											role: 'radio',
										}}
									/>
								}
								label={
									<Text variant="paragraph3" i18nKey="LBL_CONFIRM_APPT_YES" />
								}
							/>
							<FormControlLabel
								sx={{
									height: '30px',
									paddingLeft: '0px',
									paddingTop: '12px',
								}}
								value={Strings.CONFIRM_APPT_NO}
								control={
									<CustomRadio
										disableRipple
										inputProps={{
											'aria-checked': value === Strings.CONFIRM_APPT_NO,
											role: 'radio',
										}}
									/>
								}
								label={
									<Text variant="paragraph3" i18nKey="LBL_CONFIRM_APPT_NO" />
								}
							/>
						</RadioGroup>
					</FormControl>

					{value === Strings.CONFIRM_APPT_NO && (
						<Box
							sx={{
								paddingLeft: 2,
								borderLeft: 1,
								marginTop: 2,
								marginLeft: '5px',
							}}
						>
							<Stack>
								<Select
									sx={{
										borderRadius: 20,
										padding: 0,
										margin: 0,
										height: '48px',
										width: {
											xs: 'inherit',
											md: '15rem',
										},
										marginRight: {
											xs: '10px',
											md: '0px',
										},
										boxShadow: 'none',
										backgroundColor: '#F3F6FA',
										'.MuiOutlinedInput-notchedOutline': {
											borderColor: 'background.light',
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: 'background.light',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: 'background.light',
										},
									}}
									IconComponent={(props) => (
										<ArrowDown
											{...props}
											style={{ marginRight: '6px', marginTop: '5px' }}
										/>
									)}
									SelectDisplayProps={{
										id: Strings.ADD_A_NEW_PERSON,
										'aria-label': Strings.ADD_A_NEW_PERSON,
									}}
									MenuProps={{
										background: '#F3F6FA',
										MenuListProps: {
											disablePadding: true,
										},
									}}
									value={userType}
									renderValue={() => (
										<Typography sx={{ color: 'background.light' }}>
											{userType === Strings.ADD_A_NEW_PERSON
												? Strings.ADD_A_NEW_PERSON
												: selectedUser?.firstName
													? selectedUser.firstName + ' ' + selectedUser.lastName
													: Strings.SELECT_A_PERSON}
										</Typography>
									)}
									onClick={() => {
										setName({ first: '', last: '' })
									}}
								>
									{adhocUsers?.map((user, index) => {
										return (
											<MenuItem
												key={index}
												justifyContent="space-between"
												sx={{
													backgroundColor: 'white',
													'&:hover': {
														backgroundColor: 'rgba(0, 0, 0, 0.04)', // Background color on hover
													},
													'&:focus': {
														backgroundColor: 'rgba(0, 0, 0, 0.04)', // Background color on hover
													},
													margin: 0,
													padding: '12px',
												}}
												onClick={() => handleMenuItemClick(user)}
											>
												<Grid container justifyContent="space-between">
													<Typography variant="paragraph3">
														{user.firstName} {user.lastName}
													</Typography>
													{user.customertype == 'AdHoc' && (
														<Typography
															onClick={() => {
																setShowDeleteConfirmDialog(true)
																setSelectedUser(user)
																setIsEmailSelected(true)
															}}
														>
															<TrashIcon fillColor="#13499F" />
														</Typography>
													)}
												</Grid>
											</MenuItem>
										)
									})}
									<MenuItem
										justifyContent="space-between"
										sx={{
											backgroundColor: '#DDE5F1',
											margin: 0,
											display: 'flex',
											width: '100%',
											justifyContent: 'space-between',
											padding: '12px',
											'&:focus': {
												backgroundColor: 'rgba(0, 0, 0, 0.04)', // Background color on hover
											},
										}}
										component="button"
										variant="text"
										onClick={() => {
											setUserType(Strings.ADD_A_NEW_PERSON)
											setIsEmailSelected(true)
											setPhoneNumber('')
											setEmailAddress('')
										}}
									>
										<Text
											variant="paragraph3"
											i18nKey="BTN_ADD_A_NEW_PERSON"
											sx={{
												color: 'primary.main',
											}}
										/>
										<Plus fillColor="#13499F" />
									</MenuItem>
								</Select>

								{userType === Strings.ADD_A_NEW_PERSON && (
									<>
										<RoundedTextField
											label={Strings.FIRST_NAME}
											sx={{
												marginTop: '1rem',
												marginRight: {
													xs: '10px',
													md: '0px',
												},
												width: {
													xs: 'inherit',
													md: '15rem',
												}
											}}
											value={name.first}
											onChange={(value, error) => {
												handleChangeName('first', value, error)
											}}
											errors={[ERRORS[0], ERRORS[1]]}
											validations={{
												maxLen: 20,
												removeUnneededChars: true,
												maxSpecialChars:
													3 - (name?.last?.match(/[.'-]/g)?.length || 0) || 0,
											}}
											showError={adhocUserDuplicateNameError}
										/>
										<RoundedTextField
											label={Strings.LAST_NAME}
											value={name.last}
											sx={{
												marginRight: {
													xs: '10px',
													md: '0px',
												},
												width: {
													xs: 'inherit',
													md: '15rem',
												}
											}}
											onChange={(value, error) =>
												handleChangeName('last', value, error)
											}
											errors={[ERRORS[0], ERRORS[2]]}
											validations={{
												maxLen: 30,
												removeUnneededChars: true,
												maxSpecialChars:
													3 - (name?.first?.match(/[.'-]/g)?.length || 0) || 0,
											}}
											adhocUserDuplicateNameError={adhocUserDuplicateNameError}
										/>
									</>
								)}
							</Stack>
						</Box>
					)}

					{user && (
						<Box>
							<Stack direction="row" alignItems="center" mt="2rem">
								<Text.Bold
									sx={{
										lineHeight: '2.125rem',
										fontSize: {
											xs: '20px',
											md: '24px',
										},
									}}
									variant="h5"
									i18nKey="LBL_APPT_COMMUNICATION_PREFERENCES"
								/>

								<Tooltip
									role="tooltip"
									onOpen={() => setIsTooltipOpen(true)}
									onClick={() => setIsTooltipOpen(true)}
									onClose={() => setIsTooltipOpen(false)}
									tabIndex={0}
									open={isTooltipOpen}
									placement="bottom-start"
									arrow
									aria-label={strings.MSG_COMMUNICATION_PREFERENCES_TOOLTIP}
									title={
										<CustomAppointmentToolTip
											setIsTooltipOpen={setIsTooltipOpen}
										/>
									}
									componentsProps={{
										tooltip: {
											sx: {
												backgroundColor: '#D4E4FE',
												color: '#13499F',
												fontSize: 11,
												minWidth: {
													xs: '80px',
													md: '400px',
												},
												margin: {
													xs: '20px',
													md: '0px',
												},
												padding: '24px',
												'& .MuiTooltip-arrow': {
													color: '#D4E4FE',
													fontSize: "large"
												},
											},
										},
									}}
									PopperProps={{
										sx: {
											zIndex: 10,
										},
									}}
								>
									<IconButton>
										{isTooltipOpen ? (
											<InformationFilledIcon />
										) : (
											<InformationIcon />
										)}
									</IconButton>
								</Tooltip>
							</Stack>

							<Stack direction="row" alignItems="center" gap="12px" mt="16px">
								{value === Strings.CONFIRM_APPT_NO ? (
									<Stack direction="row" alignItems="center" gap="12px">
										<Checkbox
											id="emailCkBox"
											name="email"
											aria-label="email"
											sx={{
												padding: '0px',
											}}
											value={isEmailSelected}
											checked={isEmailSelected}
											onClick={() => setIsEmailSelected(!isEmailSelected)}
											icon={<UncheckedCheckbox width="20px" height="20px" />}
											checkedIcon={
												<FilledCheckbox width="20px" height="20px" />
											}
											disabled={value === Strings.CONFIRM_APPT_YES}
										/>
										<InputLabel 
											htmlFor="emailCkBox" 
											sx={{
												whiteSpace: 'break-spaces'
											}}
										>
											<Text
												variant="paragraph3"
												i18nKey="LBL_EMAIL_COMMUNICATION_PREFERENCES"
												sx={{
													fontWeight: '400',
													fontSize: '0.875rem',
												}}
											/>
										</InputLabel>
									</Stack>
								) : (
									<Stack direction="row" alignItems="center" gap="12px">
										<CustomizeTooltip
											setIsTooltipOpen={setIsCheckboxTooltipOpen}
											isOpen={isCheckboxTooltipOpen}
											customToolTipLabel={
												Strings.MSG_COMMUNICATION_PREFERENCES_CHECKBOX_LABEL_TOOLTIP
											}
										>
											<Checkbox
												id="emailCkBox"
												name="email"
												aria-label="email"
												sx={{
													padding: '0px',
												}}
												value={isEmailSelected}
												checked={isEmailSelected}
												onClick={() => setIsEmailSelected(!isEmailSelected)}
												icon={<UncheckedCheckbox width="20px" height="20px" />}
												checkedIcon={
													<FilledCheckbox width="20px" height="20px" />
												}
												disabled={value === Strings.CONFIRM_APPT_YES}
											/>
											<InputLabel htmlFor="emailCkBox" />
										</CustomizeTooltip>
										<CustomizeTooltip
											setIsTooltipOpen={setIsCheckboxTextTooltipOpen}
											isOpen={isCheckboxTextTooltipOpen}
											customToolTipLabel={
												Strings.MSG_COMMUNICATION_PREFERENCES_CHECKBOX_LABEL_TOOLTIP
											}
										>
											<InputLabel htmlFor="emailCkBox" sx={{
												whiteSpace: 'break-spaces'
											}}>
												<Text
													variant="paragraph3"
													i18nKey="LBL_EMAIL_COMMUNICATION_PREFERENCES"
													sx={{
														fontWeight: '400',
														fontSize: '0.875rem',
													}}
												/>
											</InputLabel>
										</CustomizeTooltip>
									</Stack>
								)}
							</Stack>

							{value === Strings.CONFIRM_APPT_NO && (
								<Text.Bold
									sx={{
										lineHeight: '2.125rem',
										marginTop: '32px',
										fontSize: {
											xs: '1.25rem',
											md: '1.5rem',
										},
									}}
									variant="h5"
									i18nKey="LBL_APPT_CONFIRMATION"
								/>
							)}

							<Stack
								direction={{ xs: 'column', md: 'row' }}
								alignItems={{ xs: 'normal', md: 'center' }}
								gap={{ xs: '0px', md: '24px' }}
							>
								<RoundedTextField
									label={Strings.PHONE_NUMBER}
									sx={{
										marginTop: '1rem',
										width: {
											xs: '20.5rem',
											md: '15rem',
										},
									}}
									value={isPhoneNull ? '' : phoneNumber}
									onChange={(value, error) => {
										handleCommunicationPrefreences('phoneNumber', value, error)
									}}
									errors={[ERRORS[0]]}
									validations={{
										phoneNumber: true,
									}}
									disabled={value === Strings.CONFIRM_APPT_YES}
								/>

								<RoundedTextField
									label={Strings.EMAIL_ADDRESS}
									helperText={isEmailSelected ? null : '(Optional)'}
									sx={{
										marginTop: '1rem',
										width: {
											xs: '20.5rem',
											md: '15rem',
										},
									}}
									value={emailAddress}
									onChange={(value, error) => {
										handleCommunicationPrefreences('email', value, error)
									}}
									errors={[ERRORS[0]]}
									validations={{
										emailAddress: true,
									}}
									disabled={value === Strings.CONFIRM_APPT_YES}
								/>
							</Stack>
						</Box>
					)}

					<RoundedButton
						onClick={handleClickAppointment}
						sx={{
							marginTop: {
								xs: 2,
								md: 4,
							},
							width: '250px',
						}}
						variant="contained"
						disabled={checkIfDisabled()}
					>
						<Text
							i18nKey="BTN_CONFIRM_APPOINTMENT"
							variant="paragraph3"
							sx={{ color: '#FFFFFF' }}
						/>
					</RoundedButton>
				</Grid>
			</Grid>

			<ConfirmDialog
				confirmDialogId="cancel-appt-modal"
				openDialog={openDialog}
				i18nKeyForHeader="BTN_DISCARD_APPOINTMENT"
				confirmDiaglogHeaderIcon={<TrashIcon fillColor="#000" />}
				onClickHandlerClose={() => setOpenDialog(false)}
				onclickHandlerSubmit={apptDiscarded}
				i18nKeyForBtns={['BTN_DISCARD_APPOINTMENT', 'BTN_CONTINUE_BOOKING']}
			>
				<Text
					variant="paragraph2"
					i18nKey="QUERY_DISCARD_APPOINTMENT"
					sx={{
						fontWeight: {
							xs: '600',
							md: '400',
						},
					}}
				/>
			</ConfirmDialog>

			<ConfirmDialog
				openDialog={showDeleteConfirmDialog}
				onClickHandlerClose={() => setShowDeleteConfirmDialog(false)}
				onclickHandlerSubmit={() => {
					postCustomerData({
						customerId: adhocUserID ? adhocUserID : customerId,
						operationType: 'DELETE',
					}).then(() => {
						getCustomerData(user.accountId)
						setShowDeleteConfirmDialog(false)
						setOpenToast(true)
						setSelectedUser({
							firstName: '',
							lastName: '',
						})
						setPhoneNumber('')
						setEmailAddress('')
					})
				}}
				confirmDialogHeader={Strings.REMOVE_USER}
				confirmDiaglogHeaderIcon={<TrashIcon fillColor={'black'} />}
			>
				<Typography variant="paragraph2">
					{Strings.REMOVE_MESSAGE.replace(
						'{USER_NAME}',
						selectedUser?.firstName + ' ' + selectedUser?.lastName
					)}
				</Typography>
			</ConfirmDialog>

			<ConfirmDialog
				confirmDialogId="extend-session"
				openDialog={sessionDialog}
				i18nKeyForHeader="LBL_TIMEOUT_WARNING"
				confirmDiaglogHeaderIcon={<TimerIcon />}
				onClickHandlerClose={(event) => {
					if (event === 'close') {
						setSessionDialog(false)
					} else {
						apptDiscarded()
					}
				}}
				onclickHandlerSubmit={() => {
					handleExtendSession()
				}}
				i18nKeyForBtns={['BTN_EXTEND_TIMEOUT', 'BTN_DISCARD_APPOINTMENT']}
			>
				<Text variant="paragraph2">
					<Stack direction="row" alignItems="center" gap="5px">
						<Box fontWeight="bold">
							{Strings.QUERY_TIMEOUT_WARNING_BODY}
							<span
								style={{
									fontWeight: '400',
									marginLeft: '5px',
								}}
							>
								{formattedTime?.split(':')[1]} {'seconds'}
							</span>
						</Box>
					</Stack>
				</Text>
			</ConfirmDialog>

			<Toast
				open={openToast}
				alertCode={750}
				handleClose={() => setOpenToast(false)}
			/>
		</Grid>
	)
}

export default ConfirmAppointment
