import { useEffect, useState } from 'react'
import { useLazyGetGeocodeQuery } from 'store/services/locationApi'

/* istanbul ignore next */
const useGeoLocation = () => {
	const [geoLocationState, setGeoLocationState] = useState({
		loading: true,
		coordinates: null,
		errorMessage: null,
	})

	const [ getGeocode ] = useLazyGetGeocodeQuery()

	const onSuccess = (position) => {
		const {
			coords: { latitude, longitude },
		} = position
		if(process.env.REACT_APP_GOOGLE_API_OPTIMIZATION === 'true') {
			setGeoLocationState({
				loading: false,
				geoAddress: 'Current Location',
				coordinates: { latitude, longitude },
				errorMessage: null,
			})
		}
		else {
			fetch(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&components=country:CA&key=${process.env.REACT_APP_GEO_CODING_API_KEY}`
			)
				.then((res) => res.json())
				.then((address) => {
					if (address.status === 'OK') {
						setGeoLocationState({
							loading: false,
							geoAddress: address.results[0].formatted_address,
							coordinates: { latitude, longitude },
							errorMessage: null,
						})
					}
				})
		} 
	}

	const onError = () => {
		setGeoLocationState({
			loading: false,
			coordinates: null,
			errorMessage: 'Unable to retrieve your location',
		})
	}

	const findMyLocation = () => {
		if (!navigator.geolocation) {
			setGeoLocationState({
				loading: false,
				coordinates: null,
				errorMessage: 'Geolocation not supported',
			})
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError)
		}
	}

	const findLocationForAddress = (address) => {
		
		if (address) {
			if (process.env.REACT_APP_GOOGLE_API_OPTIMIZATION === 'true') {
				getGeocode(address).then((response) => {
					handleAddressResponse(response.data);
				})
			} else {
				fetch(
					`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:CA&key=${process.env.REACT_APP_GEO_CODING_API_KEY}`
				)
					.then((res) => res.json())
					.then((address) => {
						handleAddressResponse(address);
					})
			}

			const handleAddressResponse = (address) => {
				if (address.status === 'OK') {
					const results = address.results[0]
					const location = results.geometry?.location

					setGeoLocationState({
						loading: false,
						geoAddress: results.formatted_address,
						coordinates: {
							latitude: location.lat,
							longitude: location.lng
						},
						errorMessage: null,
					})
				} else if(address.status === 'ZERO_RESULTS') {
					setGeoLocationState({
						loading: false,
						coordinates: null,
						errorMessage: 'Unable to retrieve your location',
					})
				}
			}
		}

	}

	const resetGeolocationState = () => {
		setGeoLocationState({
			loading: false,
			geoAddress: "",
			coordinates: null,
			errorMessage: null,
		})
	}

	return { findMyLocation, findLocationForAddress, geoLocationState, resetGeolocationState }
}

export default useGeoLocation
