import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { MCC_CUSTOMER_API, COMMON_PATH } from './url'
import { transformErrorResponse } from './service-utils'

export const transformResponse = (data) => {
    return data || {}
};

export const prepareHeaders = (headers, { getState }) => {
    const token = getState().auth.token
    if (token) {
        headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
}

const dynamicBaseQuery = async (args, api, extraOptions) => {
	const baseUrl = sessionStorage.getItem('id4_url') + COMMON_PATH;
	const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });
	return rawBaseQuery(args, api, extraOptions);
};

export const mccCustomerApi = createApi({
	baseQuery: dynamicBaseQuery,
	reducerPath: 'mccCustomers',
	endpoints: (build) => ({
		getMCCCustomerData: build.query({
			query: () => `${MCC_CUSTOMER_API}`,
            transformResponse,
			transformErrorResponse
		})
	}),
})

export const { useLazyGetMCCCustomerDataQuery } = mccCustomerApi
export const resetMccCustomerApi = mccCustomerApi.util.resetApiState;
