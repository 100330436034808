import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import './loader.css'
import { CANCEL_APPT, ID4_AUTH, ID4_AUTH_BC, ID4_BC, ID4_ON } from 'utils/constants/routes-constants'
import { useAuth } from 'hooks/useAuth'
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie'
import { generateRandomString } from 'utils/common.utils'

const Login = () => {
	const province = useSelector(state => state.selectedProvince);
	const location = useLocation()
	
	useEffect(() => {
		localStorage.setItem('redirectionPathName', location.state?.from?.pathname || '');
		if (!sessionStorage.getItem('id4_url')) {
			const selectedProvinceByPSC = sessionStorage.getItem('selectedProvince')?.toLowerCase() || '';
			const sessionProv = sessionStorage.getItem('prov')?.toLowerCase() || '';
			let id4Url;
			if(location.state?.from?.search === ''){
				 id4Url = (selectedProvinceByPSC || sessionProv) === 'bc' ? ID4_BC : ID4_ON;
			} else{
				 id4Url = location.state?.from?.search?.toLowerCase()?.includes('bc') ? ID4_BC : ID4_ON;
			}
			sessionStorage.setItem('id4_url', id4Url);
		}
	}, [location]);

	// Will remove this commented code once it stablizes
	// const getLoginRedirect = () => {
	// 	let returnURL = ''
	// 	const id4ProvinceUrl = sessionStorage.getItem('id4_url');
	// 	const selectedProvinceByPSC = sessionStorage.getItem('selectedProvince');

	// 	let selectedProvince;
	// 	if (id4ProvinceUrl && id4ProvinceUrl?.includes('-bc-')) {
	// 		selectedProvince = 'bc';
	// 	} else {
	// 		selectedProvince = 'on';
	// 	}

	// 	if(sessionStorage.getItem('prov') && sessionStorage.getItem('source')){
	// 		selectedProvince = sessionStorage.getItem('prov')?.toLowerCase();
	// 	}

	// 	const isSpecialCase = (
	// 		(selectedProvinceByPSC?.toLowerCase() === 'bc' && (selectedProvince?.toLowerCase() === 'on' || selectedProvince?.toLowerCase() === 'sk')) ||
	// 		(selectedProvince?.toLowerCase() === 'bc' && (selectedProvinceByPSC?.toLowerCase() === 'on' || selectedProvinceByPSC?.toLowerCase() === 'sk'))
	// 	);

	// 	if (process.env.REACT_APP_ENV === 'dev')
	// 		returnURL = 'http://localhost:3000/login-return'
	// 	else
	// 		returnURL = process.env.REACT_APP_ID4_REDIRECTION_URL
		
	//  const randomNonce = generateRandomString(); 
	// 	if ((selectedProvince === 'bc')) {
	// 		let loginURL = ID4_AUTH_BC.replace('{REDIRECT_URL}', returnURL)
	// 		if(selectedProvince && selectedProvinceByPSC){
	// 			if(selectedProvince?.toLowerCase() === selectedProvinceByPSC?.toLowerCase() && !isSpecialCase){
	// 				return loginURL
	// 			} else {
	// 				return loginURL.concat('&prompt=login')
	// 			}
	// 		} else {
	// 			return loginURL
	// 		}
	// 	}
	// 	else {
	// 		let loginURL = ID4_AUTH.replace('{REDIRECT_URL}', returnURL).replace('{IDP_NONCE}', randomNonce)
	// 		if(selectedProvince && selectedProvinceByPSC){
	// 			if(selectedProvince?.toLowerCase() === selectedProvinceByPSC?.toLowerCase() && !isSpecialCase){
	// 				return loginURL
	// 			} else {
	// 				return loginURL.concat('&prompt=login')
	// 			}
	// 		} else {
	// 			return loginURL
	// 		}
	// 	}		
	// }

	const getLoginRedirect = () => {
		const id4ProvinceUrl = sessionStorage.getItem('id4_url');
		const selectedProvinceByPSC = sessionStorage.getItem('selectedProvince')?.toLowerCase() || '';
		const sessionProv = sessionStorage.getItem('prov')?.toLowerCase() || '';
		const sessionSource = sessionStorage.getItem('source');

		let selectedProvince = (id4ProvinceUrl?.includes('-bc-') || id4ProvinceUrl?.includes('bc-api')) ? 'bc' : 'on';
		if (sessionProv && sessionSource) {
		  selectedProvince = sessionProv;
		}
		
		const locationParams = location.state?.from?.search?.toLowerCase()?.includes('bc');
		if(locationParams){
			selectedProvince = locationParams ? 'bc' : 'on';
		}

		const isSpecialCase = (
		  (selectedProvinceByPSC === 'bc' && (selectedProvince === 'on' || selectedProvince === 'sk')) ||
		  (selectedProvince === 'bc' && (selectedProvinceByPSC === 'on' || selectedProvinceByPSC === 'sk'))
		);
	
		const returnURL = process.env.REACT_APP_ENV === 'dev' 
		  ? 'http://localhost:3000/login-return' 
		  : process.env.REACT_APP_ID4_REDIRECTION_URL;
	
		const loginURLTemplate = selectedProvince === 'bc' ? ID4_AUTH_BC : ID4_AUTH;
		const randomNonce = generateRandomString();
		let loginURL = loginURLTemplate.replace('{REDIRECT_URL}', returnURL).replace('{IDP_NONCE}', randomNonce);
		
		if (selectedProvince && selectedProvinceByPSC) {
		  if (selectedProvince === selectedProvinceByPSC && !isSpecialCase) {
			if(sessionStorage.getItem('activityId') && sessionStorage.getItem('customerId')){
				sessionStorage.setItem('redirectionPath',CANCEL_APPT);
				return loginURL.concat('&prompt=login');
			} else {
				return loginURL;
			}
		  } else {
			//return loginURL.concat('&prompt=login');
			//Temp fix for 157062.  code is not using the province query string the second time.
			return loginURL;
		  }
		} else {
		  return loginURL;
		}
	}
	const redirectTo = getLoginRedirect();

	useEffect(() => {
		if(redirectTo){
			Cookies.remove('authToken');
			window.location.href = redirectTo
		}
	}, [redirectTo])

	return (
		<div className="loader-container">
			<div className="loader"></div>
		</div>
	)
}

export default Login
