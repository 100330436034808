import { Typography, Box, Stack, Link } from '@mui/material'
import { Strings } from 'utils/Strings'

import { ReactComponent as ArrowLeftBlue } from 'assets/Arrow-Left-Blue.svg'
import { ReactComponent as TimerIcon } from 'assets/Timer-Icon.svg'
import { ReactComponent as Results } from 'assets/Results.svg'
import { ReactComponent as ConfirmCalendar } from 'assets/ConfirmCalendar.svg'
import { ReactComponent as EmailShareIcon } from 'assets/EmailShareIcon.svg'
import { ReactComponent as PersonOutline } from 'assets/PersonOutline.svg'
import { ReactComponent as PrintDetailsIcon } from 'assets/PrintDetailsIcon.svg'
import { ReactComponent as LocationPin } from 'assets/LocationPin.svg'

import IconGrid from 'components/data-display/IconGrid/IconGrid'
import RoundedButton from 'components/buttons/RoundedButton'
import AddToCalendarButton from 'components/buttons/AddToCalendarButton/AddToCalendarButton'
import { useSelector } from 'react-redux'
import Text from 'components/Text'
import { DATE_FORMAT } from 'utils/constants/time-constants'
import moment from 'moment'
import { useSaveEmlFile } from 'hooks/useSaveEmlFile'
import IconLink from 'components/data-display/IconLink'
import Directions from 'features/searchresults/Directions'
import { usePrintAppointment } from 'hooks/usePrintAppointment'
import { VERTICAL_BAR } from 'utils/constants/location-constants'
import CancelAppointmentButton from 'components/buttons/CancelAppointmentButton/CancelAppointmentButton'
import useCountdownTimer from 'hooks/useCountDownTimer'

const AppointLeftPanelActions = ({
	options = {},
	actions = {},
	appointment = {},
	user = {}
}) => {

	const saveEmalFile = useSaveEmlFile(
		user
	)
	const printAppointment = usePrintAppointment(user);

	return (
		<Box>
			<Stack id="calendar-cancel-ctas" sx={{ padding: {
						xs: "24px",
						md: "0px"
					}}}>
				{
					options.showAddToCalendar && (
						<AddToCalendarButton
							appointment={appointment} 
							user={user}
						/>
					)
				}
				<CancelAppointmentButton
					handleCancelAppointment={actions.handleCancelAppointment} />

			</Stack>
			<Box 
				id="print-share-ctas" 
				mt={{ xs: 0, md: 4 }} 
				sx={{ 
					display: {
						xs: "flex",
						md: "inherit"
					},
					justifyContent: {
						xs: "space-around",
						md: "normal"
					},
					alignItems: {
						xs: "center",
						md: "normal"
					},
				}}
				mb={{ xs: 2}}
			>
				{options.showPrintBtn && (
					<IconLink
						id="handle-print-link"
						muiIcon={<PrintDetailsIcon />}
						onClick={printAppointment}
						text={Strings.LINK_PRINT_DETAILS}
					/>
				)}
				<IconLink
					id="email-share-link"
					mt={1}
					muiIcon={<EmailShareIcon />}
					onClick={saveEmalFile}
					text={Strings.LINK_SHARE_BY_MAIL}
				/>
			</Box>
		</Box>
	)
}

const AppointmentLeftPanel = ({
	options = {
		showBackButton: true,
		showTimer: true,
		showLeftBorder: true,
		showUserName: false,
		showPrintBtn: true,
	},
	actions = {},
	user = {},
	formattedTime,
	...props
}) => {
	const testType = useSelector(state => state.appointment.appointment.appointmentType)
	const duration = useSelector(state => state.appointment.appointment.duration)
	const appointmentDate = useSelector(state => state.appointment.appointment.appointmentDate)
	const appointmentTime = useSelector(state => state.appointment.appointment.startTime)
	const selectedLocation = useSelector(state => state.appointment.appointmentLocation)
	return (
		<Box
			sx={{
				backgroundColor: '#F3F6FA',
				padding: {
					xs: '0rem',
					md: '1rem 3rem'
				},
				height: 'auto',
				paddingBottom: {
					md: '6rem',
				},
				paddingLeft: {
					md: '8rem',
				},
				minHeight: {
					md: '100vh'
				},
				minWidth: {
					md: '18%'
				},
				maxWidth: {
					md: '30%'
				},
				...props.sx,
			}}
		>	
			<Stack sx={{
				flexDirection: {
					xs: "row",
					md: "column"
				},
				alignItems: {
					xs: "center",
					md: "normal"
				},
				justifyContent: {
					xs: "space-between",
					md: "normal"
				},
				alignContent: {
					xs: "center",
					md: "normal"
				},
				marginTop: {
					xs: "20px",
					md: "0px"
				},
				marginLeft: {
					xs: "10px",
					md: "0px"
				},
				marginRight: {
					xs: "10px",
					md: "0px"
				}
			}}
			>
			{options.showBackButton && (
				<RoundedButton
					variant="outlined"
					sx={{
						backgroundColor: '#FFFFFF',
						display: 'flex',
						width: 'fit-content',
					}}
					onClick={actions?.handleClickBack}
				>
					<ArrowLeftBlue />
					<Typography
						variant="paragraph3"
						sx={{ color: '#13499F', marginLeft: 1 }}
					>
						{Strings.BTN_BACK}
					</Typography>
				</RoundedButton>
			)}

			{options.showTimer && (
				<Box
					id="timer-icon"
					sx={{
						backgroundColor: '#D4E4FE',
						padding: { md: '1rem', xs: '8px 12px' },
						display: 'flex',
						alignItems: 'center',
						marginTop: { md: 6, xs: 0 },
					}}
				>
					<TimerIcon />
					<Typography variant="paragraph3" ml={1}>
						{Strings.LBL_TIMEOUT}: {formattedTime}
					</Typography>
				</Box>
			)}
			</Stack>
			<Box sx={{
				marginLeft: {
					xs: "24px",
					md: "0px"
				},
				padding: {
					xs: "10px",
					md: "0px"
				}
			}}
			>
			<Text.Bold
				className='appt-title'
				sx={{
					marginTop: {
						md: 4,
						xs: 2
					},
					fontSize: '1.3rem',
					
				}}
				i18nKey='LBL_APPOINTMENT_DETAILS'
			/>

			<Box
				id='appointment-left-panel'
				sx={{
					paddingLeft: options.showLeftBorder ? 2 : 0,
					borderLeft: options.showLeftBorder ? 1 : 0,
					borderColor: { xs:'#0E397E33', md: 'inherit'},
				}}
			>
				{options.showUserName && (
					<Stack 
						direction="row" 
						mt={2} 
						sx={{ 
							gap: "10px", 
							alignItems: "center" 
						}}
					>
						<PersonOutline />
						<Typography
							variant="paragraph3"
							sx={{ fontWeight: 'bold', marginLeft: 1 }}
						>
							{user?.firstName + ' ' + user?.lastName}
						</Typography>
					</Stack>
				)}

				<IconGrid muiIcon={<Results />} mt={2}>
					<Typography
						variant="paragraph3"
						sx={{ fontWeight: 'bold', marginLeft: 1 }}
					>
						{testType}{VERTICAL_BAR}
						<span style={{ fontWeight: '300' }}>{duration}</span>
					</Typography>
				</IconGrid>

				<IconGrid
					muiIcon={<LocationPin stroke="black" />}
					centerIcon={false} mt={2}>
					<Stack sx={{ maxWidth: '100%' }} ml={1}>
						<Typography
							variant="paragraph3"
							sx={{ fontWeight: 'bold' }}
						>
							{selectedLocation.siteName}
						</Typography>
						<Typography variant="paragraph3">
							{selectedLocation.address?.address1},
							{selectedLocation.address?.address2},
						</Typography>
						<Typography variant="paragraph3">
							{selectedLocation.address?.city}{', '}
							{selectedLocation.address?.province}
						</Typography>
					</Stack>
				</IconGrid>

				{options.showDirections &&
					<Directions 
						className='noPrint'
						locationCordinate={{ ...selectedLocation.address }} 
					/>
				}

				<IconGrid
					muiIcon={<ConfirmCalendar />}
					centerIcon={false}
					marginTop={2}>
					<Stack direction="column">
						<Typography
							variant="paragraph3"
							sx={{
								fontWeight: 'bold',
								marginLeft: 1
							}}
						>
							{moment(appointmentDate).format(DATE_FORMAT)}
						</Typography>
						<Typography variant="paragraph3" sx={{ marginLeft: 1 }}>
							{'at '}
							{appointmentTime}
						</Typography>
					</Stack>
				</IconGrid>
			</Box>
			</Box>
			{props.children}
		</Box>
	)
}

AppointmentLeftPanel.Actions = AppointLeftPanelActions

export default AppointmentLeftPanel
