import Box from '@mui/material/Box'
import CancelAppointment from 'features/ViewAppointment/CancelAppointment'
import Body from 'features/body/body'
import {
	searchSource,
	updateStaffAddress,
	updateStaffCustomerId,
	updateStaffSiteId,
} from 'features/searchresults/searchSlice'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useLazyGetCustomerDataQuery } from 'store/services/customerApi'
import {
	UnauthenticatedTemplate,
	AuthenticatedTemplate,
} from '@azure/msal-react'
import { SignInButton } from './SignIn'
import {
	getAuthToken,
	getItem,
	getItemFromBrowserStorage,
	removeAuthToken,
	removeItemFromBrowserStorage,
	saveTokenType,
	setAuthToken,
	setItemFromBrowserStorage,
} from 'utils/common.utils'
import TagManager from 'react-gtm-module'
import { useLoadScript } from '@react-google-maps/api'
import { setApiState } from 'store/reducers/mapReducer'
import { resetTimeSlotsApi } from 'store/services/locationTimeSlotsApi'
import { resetMccCustomerApi } from 'store/services/mccCustomerApi'
import { setJWTToken } from 'store/reducers/authReducer'
import { TOKEN_TYPE } from 'utils/constants/common.constants'

import { useNavigate } from 'react-router-dom'
import { CANCEL_APPT, ID4_BC, ID4_ON } from 'utils/constants/routes-constants'

// GOOGLE TAG MANAGER
const tagManagerArgs = {
	gtmId: 'GTM-WSPMWZ27',
}
const libraries = ['maps', 'places']

TagManager.initialize(tagManagerArgs)

const Home = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const auth = useAuth()

	const currentUrl = window.location.href
	const searchqueryParams = new URL(currentUrl)

	const source = searchqueryParams.searchParams.get('source')

	const [getCustomerData, { data }] = useLazyGetCustomerDataQuery('')

	const { loadTrigger } = useSelector(state => state.map)

	
	const { isLoaded } = useLoadScript(
		{
			googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
			libraries,
			async: true,
		}
	)

	useEffect(() => {
		/* istanbul ignore else */
		if (isLoaded && loadTrigger) {
			dispatch(setApiState(isLoaded))
		}
	}, [isLoaded, loadTrigger, dispatch])

	useEffect(() => {
		if (data) {
			auth.login(data)
		}
	}, [data])

	let [searchParams] = useSearchParams()
	const [showCancelScreen, setShowCancelScreen] = useState(false)
	const [activityId, setActivityId] = useState()
	const accessToken = useSelector((state) => state.auth.token)
	const [userId, setUserId] = useState()
	const [hash, setHash] = useState()

	useEffect(() => {
		for (const entry of searchParams.entries()) {
			const paramKey = (entry[0] || '').trim().toLowerCase()
	
			if (paramKey === 'src' || paramKey === 'source') {
				dispatch(searchSource(entry[1]))
			}

			if (paramKey === 'activityid') {
				setActivityId(entry[1].replace(/[<>]/g, ''))
			}

			if (paramKey === 'customerid') {
				setUserId(entry[1].replace(/[<>]/g, ''))
			}

			if (paramKey === 'addr') {
				dispatch(updateStaffAddress(entry[1]))
			}

			if (paramKey === 'cid') {
				if (accessToken !== null) {
					sessionStorage.setItem('staffSource','D365');
					getCustomerData(entry[1])
					dispatch(updateStaffCustomerId(entry[1]))
				}
			}

			if (paramKey === 'sid') {
				dispatch(updateStaffSiteId(entry[1]))
			}

			if (paramKey === 'source' && entry[1] === 'cancel') {
				sessionStorage.setItem(
					'activityId',
					searchqueryParams.searchParams?.get('activityId').replace(/[<>]/g, '')
				)
				sessionStorage.setItem(
					'customerId',
					searchqueryParams.searchParams?.get('customerId').replace(/[<>]/g, '')
				)
				sessionStorage.setItem('prov', searchqueryParams?.searchParams?.get('province')?.toLowerCase())
				sessionStorage.setItem(
					'id4_url',
					searchqueryParams?.searchParams?.get('province')?.toLowerCase() === 'bc' ? ID4_BC : ID4_ON
				)
				navigate(CANCEL_APPT)
			}

			if (paramKey === 'hash') {
				setHash(entry[1].replace(/[<>]/g, ''))
			}
		}
	}, [searchParams, dispatch, accessToken])
	
	useEffect(() => {
		if (
			!window.location.href.includes('/appointments') &&
			sessionStorage?.getItem('source') !== 'mcc' &&
			source != 'mcc' &&
			(getItem('SKIP_SSO') === 'true' || source !== 'D365')
			&& sessionStorage?.getItem('staffSource') !== 'D365'
		) {
			removeItemFromBrowserStorage('user')
			removeAuthToken()
			auth?.logout()
			dispatch(resetTimeSlotsApi())
			dispatch(resetMccCustomerApi())
		}

		if(localStorage?.getItem('selectedProvince') && sessionStorage?.getItem('source') === 'mcc' && !sessionStorage?.getItem('prov')){
			sessionStorage.setItem('prov', sessionStorage.getItem('selectedProvince')?.toLowerCase());
		}
	}, [])

    /* istanbul ignore next */
    return (
        source == 'D365' ? (
            <Box role='main'>
                <Body />
            </Box>
        ) :
            <Box role='main'>
                {
                    showCancelScreen ?
                        <CancelAppointment activityId={activityId} hash={hash} />
                        : <Body />
                }

            </Box>
    );
}

export default Home
