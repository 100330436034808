import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { usePlacesService } from '../features/searchresults/maps/PlaceServiceContext'

export function useAddressPredictions(input, predictionClicked) {
    const { isGoogleApiLoaded, loadTrigger } = useSelector((state) => state.map)
    const [predictions, setPredictions] = useState([])
    const [ isServiceInitialized, setIsServiceInitilized ] = useState(false)
    const [useSessionToken, setUseSessionToken] = useState(false)
    let retrySession = false;

    const cache = useRef(new Map())
    const localstoragecache = useRef()

    const autocomplete = useRef()
    const sessionToken = useRef()
    const placeService = useRef()

	const mapRef = usePlacesService();

    const clearPredictionsCache = useCallback(() => {
        for(const [key, val] of cache.current.entries()){
            cache.current.delete(key)
        }
    })

    const getPredictionsCache = (input) => {
        localstoragecache.current = null
        cleanPredictionsLocalStorage()
        const localprediction = localStorage.getItem(`prediction_${input}`)
        if(localprediction){
            localstoragecache.current = JSON.parse(localprediction)
        }
        if(localstoragecache.current){
            return localstoragecache.current.results
        }else if(cache.current.get(input)){
            return cache.current.get(input).results
        }
        else{
            return null
        }
    }

    const cleanPredictionsLocalStorage = () => {
        const now = new Date().getTime()
        Object.keys(localStorage)
            .filter(key => key.includes('prediction_'))
            .map(key => {
                const expirytime = JSON.parse(localStorage.getItem(key)).expiry 
                if(expirytime < now){
                    localStorage.removeItem(key)
                }   
            })
    }

    const setNewSessionToken = () => {
        if(isServiceInitialized){
            sessionToken.current = new window.google.maps.places.AutocompleteSessionToken()
        }
    }
    
    useEffect(() =>{    
        const initializeServices = () => {
            if(!window.google || !window.google.maps || !window.google.maps.places){
                console.log("Google Map not available yet")
                return false
            }
            try{
                const useToken = process.env.REACT_APP_USE_SESSION_TOKEN === 'true'
                setUseSessionToken(useToken);
                autocomplete.current = new window.google.maps.places.AutocompleteService()
                if(useToken && autocomplete.current){
                    setNewSessionToken()
                }
                setIsServiceInitilized(true)
                return true
            }
            catch(error){
                console.error("Error initializing services:", error)
                return false;
            }
            
        }

        if (loadTrigger && !isServiceInitialized) {
            const timeoutId = setTimeout(() => {
                const initialized = initializeServices();
                if(!initialized){
                    const retryInterval = setInterval(() => {
                        const success = initializeServices()
                        if(success){
                            clearInterval(retryInterval)
                        }
                    }, 1000)

                    //Clear retry after 10 seconds
                    setTimeout(() => {
                        clearInterval(retryInterval)
                        if(!isServiceInitialized){
                            console.error("Failed to start service after multiple attempts")
                        }
                    }, 10000)
                }
            }, 100)
            return () => clearTimeout(timeoutId)
        }
    },[loadTrigger, isServiceInitialized])

    function getPlacePredictions(input) {
        const cachedResults = getPredictionsCache(input)
        if(autocomplete.current && cachedResults == null){
            //console.log("using session token:" , sessionToken.current)
            autocomplete.current.getPlacePredictions(
                {
                    input,
                    componentRestrictions: { country: 'ca' },
                    sessionToken: useSessionToken ? sessionToken.current : undefined,
                    type: 'street_address',
                },
                (predictions, status) => {
                    if(status === window.google.maps.places.PlacesServiceStatus.OK){
                        if(cache.current.size > 40){
                            clearPredictionsCache();
                        }
                        cache.current.set(input, {results: predictions})
                        localStorage.setItem(`prediction_${input}`,JSON.stringify({results: predictions, expiry: new Date().getTime() + 24 * 60 * 60 * 1000 }))
                        setPredictions(predictions && predictions.map((prediction) => {
                            return { description: prediction.description,
                                     placeId: prediction.place_id
                                }
                        }))
                    }
                    else if(status === window.google.maps.places.PlacesServiceStatus.ERROR || status ===  window.google.maps.places.PlacesServiceStatus.INVALID_REQUEST) {
                        console.log('Error fetching predictions')
                    }
            })
        }else if(cachedResults){
            console.log("Cached prediction")
            setPredictions(cachedResults && cachedResults.map((prediction) => {
                return { description: prediction.description,
                         placeId: prediction.place_id
                    }
            }))
            
        }
    }

    const getPlaceDetails = (placeId) => {
        if(!placeService.current){
            placeService.current = new window.google.maps.places.PlacesService(mapRef.current)
        }
        return new Promise((resolve, reject) => {
            if(!autocomplete.current) {
                reject('Error fetching place details')
            }
            if(placeService.current) {
                    //console.log("places using session token:" , sessionToken.current)
                    placeService.current.getDetails(
                        {
                            placeId: placeId,
                            sessionToken: useSessionToken ? sessionToken.current : undefined,
                            fields: ['name', 'geometry']
                        },
                        (place, status) => {
                            if(status === window.google.maps.places.PlacesServiceStatus.OK) {
                                if(useSessionToken){
                                    setNewSessionToken()
                                }
                                
                                resolve(place);
                            } else {
                                reject('Error fetching place details')
                            }
                        }
                    )
                }else{
									console.log("Unable to fetch placeService")
								}
        })
    }

    const debouncedGetPlacePredictions = useMemo(
        () => debounce(getPlacePredictions, 500),
        []
    )

    useEffect(() => {
        const useToken = process.env.REACT_APP_USE_SESSION_TOKEN === 'true'
        if(useToken){
            setUseSessionToken(useToken);
        }
    }, [])

		/*
    const updatePlacesService  = (gmap, maptest) => { 
				if(window.google){
					console.log('gmap=========================> ', gmap)
					console.log('maptest=========================> ', maptest)
					placeService.current = new window.google.maps.places.PlacesService(gmap)
				}	
    }
		*/

    useEffect(() => {
        if(isServiceInitialized && !predictionClicked){
            if (input?.replace(/ /g, '')?.length > 2) {
                debouncedGetPlacePredictions(input)
            }
        }
    }, [debouncedGetPlacePredictions, input, isServiceInitialized])

    
    

    return { predictions, getPlaceDetails, setNewSessionToken, useSessionToken }
}