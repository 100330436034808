import { Backdrop, Box, CircularProgress, IconButton } from '@mui/material'
import LifeLabsLogo from 'assets/lifelabs-logo.svg'
import { useSelector } from 'react-redux'

const CustomLoader = () => {

    const showLoader = useSelector((state)=> state?.loader);
    return (
        <Backdrop
            sx={(theme) => ({ color: '#ffffff', zIndex: theme.zIndex.drawer + 1 })}
            open={showLoader}
            color='#ffffff'
        >
            <Box sx={{ position: 'relative', display: 'inline-flex', verticalAlign: 'middle' }}>
                <IconButton disabled={showLoader}>
                    <img src={LifeLabsLogo} width={50} height={50} />
                </IconButton>
                <svg width={0} height={0}>
                    <defs>
                        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#e01cd5" />
                            <stop offset="100%" stopColor="#1CB5E0" />
                        </linearGradient>
                    </defs>
                </svg>
                {showLoader && <CircularProgress size={70} sx={{ marginLeft: '-70px', marginTop: '8px', 'svg circle': { stroke: 'url(#my_gradient)' } }} />}
            </Box>
        </Backdrop>
    )
}

export default CustomLoader

