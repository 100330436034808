export const footerLinkList = [
    {
        'title': 'Patients',
        'link': 'https://www.lifelabs.com/patients/',
        'menuItem': [
            {
                title: "Tests & Services",
                link: "https://www.lifelabs.com/patients/tests-services/"
            },
            {
                title: "Preparing for a Test",
                link: "https://www.lifelabs.com/patients/preparing-for-a-test/"
            },
            {
                title: "Patient Test Instructions",
                link: "https://www.lifelabs.com/patients/preparing-for-a-test/patient-test-instructions/"
            },
            {
                title: "Billing",
                link: "https://www.lifelabs.com/patients/billing/"
            },
            {
                title: "Patient Centred Care",
                link: "https://www.lifelabs.com/patients/patient-centred-care/"
            },
            {
                title: "Mobile Lab Services",
                link: "https://www.lifelabs.com/patients/mobile-lab-services/"
            }
        ]
    },
    {
        'title': 'Healthcare Providers',
        'link': 'https://www.lifelabs.com/healthcare-providers/',
        'menuItem': [
            {
                title: "Requisitions & Collection Instructions",
                link: "https://www.lifelabs.com/healthcare-providers/requisitions/"
            },
            {
                title: "Reports",
                link: "https://www.lifelabs.com/healthcare-providers/reports/"
            },
            {
                title: "Supplies",
                link: "https://www.lifelabs.com/healthcare-providers/supplies/"
            },
            {
                title: "News & Updates",
                link: "https://www.lifelabs.com/healthcare-providers/news-updates/"
            },
            {
                title: "Education & Events",
                link: "https://www.lifelabs.com/healthcare-providers/education-events/"
            },
            {
                title: "Medical Consults",
                link: "https://www.lifelabs.com/healthcare-providers/medical-consults/"
            },
            {
                title: "Resource Hub",
                link: "https://www.lifelabs.com/healthcare-providers/resource-hub/"
            }
        ]
    },
    {
        'title': 'Corporate Customers',
        'link': 'https://www.lifelabs.com/corporate-customers/',
        'menuItem': [
            {
                title: "Employers",
                link: "https://www.lifelabs.com/corporate-customers/employers/"
            },
            {
                title: "Hospitals",
                link: "https://www.lifelabs.com/corporate-customers/hospitals/"
            },
            {
                title: "LTC & Retirement Residence",
                link: "https://www.lifelabs.com/corporate-customers/ltc-retirement-residence/"
            },
            {
                title: "Clinical Trials",
                link: "https://www.lifelabs.com/corporate-customers/clinical-trials/"
            },
            {
                title: "Digital Health Solutions",
                link: "https://www.lifelabs.com/corporate-customers/digital-health-solutions/"
            },
            {
                title: "Pharmaceutical Solutions",
                link: "https://www.lifelabs.com/corporate-customers/pharmaceutical-solutions/"
            }
        ]
    },
    {
        'title': 'Careers',
        'link': 'https://www.lifelabs.com/careers/',
        'menuItem': [
            {
                title: "Why LifeLabs",
                link: "https://www.lifelabs.com/careers/why-lifelabs/"
            },
            {
                title: "Faces of LifeLabs",
                link: "https://www.lifelabs.com/careers/faces-of-lifelabs/"
            },
            {
                title: "Current Opportunities",
                link: "https://jobs.lifelabs.com/"
            }
        ]
    },
    {
        'title': 'About Us',
        'link': 'https://www.lifelabs.com/about-us/',
        'menuItem': [
            {
                title: "About LifeLabs",
                link: "https://www.lifelabs.com/about-us/about-lifelabs/"
            },
            {
                title: "Leadership",
                link: "https://www.lifelabs.com/about-us/leadership/"
            },
            {
                title: "Innovation",
                link: "https://www.lifelabs.com/about-us/innovation/"
            },
            {
                title: "Community Report",
                link: "https://www.lifelabs.com/community-report/"
            },
            {
                title: "Sustainability",
                link: "https://www.lifelabs.com/about-us/sustainability/"
            },
            {
                title: "Diversity, Equity & Inclusion",
                link: "https://www.lifelabs.com/about-us/diversity-equity-inclusion/"
            },
            {
                title: "Global Reporting Initiative (GRI)",
                link: "https://www.lifelabs.com/global-reporting-initiative-gri/"
            },
            {
                title: "Accreditation & Licensing",
                link: "https://www.lifelabs.com/about-us/accreditation-licensing/"
            }
        ]
    },
    {
        'title': 'News',
        'link': 'https://www.lifelabs.com/news/',
        'menuItem': [
            {
                title: "Featured News",
                link: "https://www.lifelabs.com/news/featured-news/"
            },
            {
                title: "Class Action Settlement News",
                link: "https://www.lifelabs.com/were-you-a-lifelabs-customer-on-or-before-dec-17-2019/"
            },
            {
                title: "HCPs Newsletters",
                link: "https://www.lifelabs.com/news/hcps-newsletters/"
            },
            {
                title: "News Room",
                link: "https://www.lifelabs.com/news/news-room/"
            },
            {
                title: "Scientific Publications",
                link: "https://www.lifelabs.com/news/scientific-publications/"
            },
            {
                title: "Notifications & Alerts",
                link: "https://www.lifelabs.com/news/notifications-alerts/"
            },
            {
                'title': 'Contact Us',
                'link': 'https://www.lifelabs.com/contact-us/',
                'menuItem': []
            }
        ]
    },
    {
        'title': 'Contact Us',
        'link': 'https://www.lifelabs.com/contact-us/',
        'menuItem': []
    }
];
