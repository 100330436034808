import IconGrid from 'components/data-display/IconGrid/IconGrid'
import Calendar from 'assets/Calendar'
import { Stack } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { Strings } from 'utils/Strings'
import Text from 'components/Text'
import { testAttr } from 'utils/test/test.utils'
import {
	convertDateTimeForEarliestTime,
	isDateTimeInRange,
} from 'utils/dateTime.utils'
import { WaitTime } from './Content'
import useMobile from 'hooks/useMobile'

const EarliestTime = ({ id, location, handleEarlApptClick, earliestTime }) => {
	const isMobile = useMobile()
	const openTime = location?.openingTime
	const closeTime = location?.closingTime
	const isTempClose = location?.isTemporarilyClosed
	const isPermntClose = location?.isPermanentlyClosed

	return (
		<>
			{
				isDateTimeInRange(openTime, closeTime, location?.address?.timeZone) &&
				location?.waitTime &&
				location?.waitTime > 0 &&
				location?.waitTime !== '0' &&
				!isTempClose &&
				!isPermntClose && <WaitTime location={location} />
			}

			{!location?.isWalkingOnly && !isTempClose && !isPermntClose &&
				(isMobile ? (
					<>
						<Stack direction="row" alignItems="center" sx={{ margin: '0.3rem 0' }}>
							<IconGrid muiIcon={<Calendar fillColor="#000" style />}>
								<Typography variant="paragraph3">
									{Strings.EARLI_APPOINT}:
								</Typography>
							</IconGrid>
						</Stack>

						<Button
							aria-label={Strings.EARLI_APPOINT}
							{...testAttr(`earliest-app-${id}`)}
							variant="text"
							onClick={() => handleEarlApptClick(location)}
							sx={{
								paddingTop: '0px',
								justifyContent: 'start',
								marginLeft: {
									xs: '18px',
									md: 'auto'
								}
							}}
						>
							<Text.Bold
								variant="paragraph3"
								color="primary"
								sx={{ textDecorationLine: 'underline' }}
							>
								{earliestTime ||
									(location?.earliestAppt?.includes('Today')
										? location?.earliestAppt
										: convertDateTimeForEarliestTime(location?.earliestAppt))}
							</Text.Bold>
						</Button>
					</>
				) : (
					<>
						<IconGrid
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								margin: '0.3rem 0'
							}}
							muiIcon={<Calendar fillColor="#000" />}
						>
							<Stack
								direction={{ xs: 'column', md: 'row' }}
								alignItems={{ xs: 'start', md: 'center' }}
							>
								<Typography variant="paragraph3">
									{Strings.EARLI_APPOINT}:
								</Typography>
								<Button
									aria-label={Strings.EARLI_APPOINT}
									{...testAttr(`earliest-app-${id}`)}
									variant="text"
									onClick={() => handleEarlApptClick(location)}
									sx={{
										paddingLeft: '3px',
									}}
								>
									<Text.Bold
										variant="paragraph3"
										color="primary"
										sx={{ textDecorationLine: 'underline' }}
									>
										{earliestTime ||
											(location?.earliestAppt?.includes('Today')
												? location?.earliestAppt
												: convertDateTimeForEarliestTime(
														location?.earliestAppt
													))}
									</Text.Bold>
								</Button>
							</Stack>
						</IconGrid>
					</>
				))}
		</>
	)
}

export default EarliestTime
