import { createSlice } from "@reduxjs/toolkit";

const initialState = false

const loaderOverlay = createSlice({
    name: 'loaderOverlay',
    initialState,
    reducers: {
        showLoader() {
            return true;
        },
        resetLoaderStatus() {
            return initialState;
        }
    },
});

export const { resetLoaderStatus, showLoader } = loaderOverlay.actions;
export default loaderOverlay.reducer
