import React, { createContext, useContext, useRef } from 'react';

const PlacesServiceContext = createContext();

export const PlacesServiceProvider = ({ children }) => {
  const mapRef = useRef(null);

  return (
    <PlacesServiceContext.Provider value={mapRef}>
      {children}
    </PlacesServiceContext.Provider>
  );
};

export const usePlacesService = () => {
  return useContext(PlacesServiceContext);
};
