import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router'

import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { getAuthToken } from 'utils/common.utils'
import { CANCEL_APPT, LOCATION_FINDER } from 'utils/constants/routes-constants'

/* istanbul ignore next */
const ProtectedLayout = () => {

	const source = useSelector((state) => state.search.source)
	const selectedProvince = sessionStorage.getItem('selectedProvince')

	const { user } = useAuth()
	const location = useLocation();
	const authToken = getAuthToken();
	const id4ProvinceUrl = sessionStorage.getItem('id4_url');
	let id4SelectedProvince;
	if (id4ProvinceUrl && (id4ProvinceUrl?.includes('-bc-') || id4ProvinceUrl?.includes('bc-api'))) {
		id4SelectedProvince = 'bc';
	} else {
		id4SelectedProvince = 'on';
	}

	const isSpecialCase = (
		(selectedProvince?.toLowerCase() === 'bc' && (id4SelectedProvince?.toLowerCase() === 'on' || id4SelectedProvince?.toLowerCase() === 'sk')) ||
		(id4SelectedProvince?.toLowerCase() === 'bc' && (selectedProvince?.toLowerCase() === 'on' || selectedProvince?.toLowerCase() === 'sk'))
	);
	
	/* istanbul ignore next */
	return (user && authToken) && (!isSpecialCase) || source === 'D365' || (location?.pathname === LOCATION_FINDER && sessionStorage?.getItem('source') !== "mcc") ? (
		<Outlet />
	) : (
		<Navigate 
			to="/login" 
			replace={true} 
			state={{ from: location }}
		/>
	)
}

export default ProtectedLayout
