import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Box, Stack, Grid } from '@mui/material'

import { ReactComponent as AppointmentConfirmedCheck } from 'assets/AppointmentConfirmedCheck.svg'

import { useAuth } from 'hooks/useAuth'
import Toast from 'components/feedback/toast/Toast'
import Text from 'components/Text'
import IconGrid from 'components/data-display/IconGrid/IconGrid'

import AppointmentLeftPanel from './AppointmentLeftPanel'
import AppointmentConfirmedBody from './AppointmentConfirmedBody'
import { HOME, MCC_DASHBOARD } from 'utils/constants/routes-constants'
import { AppointmentStatus } from 'utils/constants/status-constants'
import { resetBookSessionApi } from 'store/services/reservationApi'
import { usePutUpdateAppointmentDetailsMutation } from 'store/services/appointmentApi'
import { getAuthToken, getItemFromBrowserStorage, removeItemFromBrowserStorage } from 'utils/common.utils'
import CancelAppointment from 'features/ViewAppointment/CancelAppointment'
import { removeAuthToken } from 'utils/common.utils'
import { DEVICE_HISTORY_STATUSES } from 'utils/constants/common.constants'
import { useUpdateDeviceHistoryMutation } from 'store/services/deviceApi'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'
import { saveState } from 'store/reducers/appStateReducer'

/* istanbul ignore next */
const AppointmentConfirmed = ({ ...props }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user } = useAuth()
	const source = useSelector((state) => state.search.source)
	const appointment = useSelector((state) => state.appointment.appointment)
	const [cancelAppointment, { data: appoinmentCancelled }] = usePutUpdateAppointmentDetailsMutation()
	const appointmentDetails = useSelector((state) => state.appointment)
	const accessToken = useSelector(
		(state) => state.auth?.token
	)
	const authToken = getAuthToken();
	const [showCancellationScreen, setShowCancellationScreen] = useState(false);
	const deviceHistoryDetails = useSelector((state) => state.deviceHistory)
	const [updateDeviceHistory] = useUpdateDeviceHistoryMutation();
	const idToken = useSelector(
		(state) => state.auth?.idToken
	)
	const state = useSelector((state) => state)

	useEffect(() => {
		if (appoinmentCancelled) {
			if (deviceHistoryDetails?.deviceHistoryId) {
				updateDeviceHistory({
					id: deviceHistoryDetails?.deviceHistoryId,
					StatusReason: DEVICE_HISTORY_STATUSES.Delete,
				})
			}
			if (source === "D365") {
				setShowCancellationScreen(true);
				setTimeout(() => {
					navigate(HOME);
				}, 30000)
			} else {
				navigate(MCC_DASHBOARD, { replace: true })
			}
		}
	}, [appoinmentCancelled])

	const handleCancelAppointment = () => {
		cancelAppointment({
			id: appointment?.activityId,
			appointmentStatus: AppointmentStatus.CUSTOMER_CANCELLATION,
		})
	}

	const [openToast, setOpenToast] = useState(true)
	const [adhocUser, setAdhocUser] = useState()

	const handleCloseToast = () => {
		setOpenToast(false)
	}

	const handleBeforeUnload = () => {
		sessionStorage.setItem('appointment', JSON.stringify(appointmentDetails));
		dispatch(saveState(state))
	};

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		dispatch(resetBookSessionApi());
		localStorage.removeItem('appState')
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			sessionStorage.removeItem('appointmentBookingTimeLeft')
		};
	}, [])


	useEffect(() => {
		if (getItemFromBrowserStorage('user')) {
			let storageUser = JSON.parse(getItemFromBrowserStorage('user'));
			if (storageUser?.selectedAdhocUser) {
				setAdhocUser({
					firstName: storageUser?.selectedAdhocUser?.firstName,
					lastName: storageUser?.selectedAdhocUser?.lastName
				})
			}
		}
	}, [])

	useEffect(()=>{
		if(window && window.QSI){
			window.QSI.API.unload();
			window.QSI.API.load();
			window.QSI.API.run();  
		}
	},[window.QSI])
	  
	useEffect(() => {
		if (accessToken === null && source !== "D365" && !authToken) {
			removeItemFromBrowserStorage('user');
			window.location.href = MCCRedirectionUrls.Logout.replace('{ID_TOKEN}',idToken).replace('{LOGOUT_REDIRECTION_URL}',process.env.REACT_APP_BASE_URL_PROD);
		}
	}, [accessToken])

	return (
		<>
			{
				showCancellationScreen ?
					<CancelAppointment activityId={appointment?.activityId} path={"APPT_CONFIRMED"} />
					:
					(<Grid
						container
						sx={{
							flexDirection: { xs: 'column', md: 'row' },
							padding: { xs: '0px 16px', md: '0px' },
						}}
						{...props}
					>

						<Box data-testid="appointment-confirmed" display="none"></Box>
						<Stack
							sx={{
								display: {
									xs: 'normal',
									md: 'none',
								},
								marginTop: '16px',
							}}
							mb={2}
							width="100%"
						>
							{
								source === "D365" &&

								<Text.Bold
									sx={{
										color: '#595959',
										fontSize: '12px',
										lineHeight: '18px',
										textDecoration: 'underline'
									}}
									i18nKey='LBL_CONFIRMATION'
								>
									{appointment.confirmationId}
								</Text.Bold>
							}
							<IconGrid
								mb={2}
								alignItems="center"
								muiIcon={<AppointmentConfirmedCheck />}
							>
								<Text
									variant="h1"
									i18nKey="MSG_BOOKINGSUCCESS_APPOINTMENT_CONFIRMED"
									ml={2}
									sx={{
										fontSize: {
											xs: '28px',
											md: '36px',
										},
										lineHeight: {
											xs: '2.375rem',
											md: '4.125rem',
										},
									}}
								/>
							</IconGrid>
							<Text
								variant="paragraph3"
								i18nKey="MSG_BOOKINGSUCCESS_COMMUNICATION_PREFERENCE"
							/>
						</Stack>
						<AppointmentLeftPanel
							options={{
								showBackButton: false,
								showTimer: false,
								showUserName: true,
								showDirections: true,
								showLeftBorder: true,
							}}
							user={adhocUser ? adhocUser : user}
							sx={{
								paddingTop: 10,
								width: '100%',
							}}
						>
							<AppointmentLeftPanel.Actions
								options={{
									showAddToCalendar: source !== 'D365',
									showPrintBtn: source !== 'D365',
								}}
								actions={{
									handleCancelAppointment,
								}}
								appointment={appointment}
								user={adhocUser ? adhocUser : user}
							/>
						</AppointmentLeftPanel>

						<Grid
							sx={{
								flexGrow: 1,
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: {
									xs: '16px',
									md: '50px',
								},
								padding: { xs: '16px 0px', md: '0px' },
							}}
							pt={10}
						>
							<Grid
								container
								flexDirection="column"
								justifyContent="center"
								alignItems="center"
								alignSelf="center"
								alignContent="center"
							>
								<Stack>
									{
										source === "D365" &&
										<Text.Bold
											sx={{
												color: '#595959',
												fontSize: '12px',
												lineHeight: '18px',
												textDecoration: 'underline'
											}}
											i18nKey='LBL_CONFIRMATION'
										>
											{appointment.confirmationId}
										</Text.Bold>
									}
									<Box sx={{ display: { xs: 'none', md: 'block' } }}>
										<IconGrid
											mb={2}
											alignItems="center"
											muiIcon={<AppointmentConfirmedCheck />}
										>
											<Text
												variant="h1"
												i18nKey="MSG_BOOKINGSUCCESS_APPOINTMENT_CONFIRMED"
												ml={2}
												sx={{ fontSize: '36px' }}
											/>
										</IconGrid>
										<Text
											variant="paragraph3"
											i18nKey="MSG_BOOKINGSUCCESS_COMMUNICATION_PREFERENCE"
										/>
									</Box>
									<Box
										sx={{
											width: {
												md: '36rem',
											},
										}}
									>
										<AppointmentConfirmedBody />
									</Box>
								</Stack>
							</Grid>
						</Grid>

						<Toast open={openToast} alertCode={700} handleClose={handleCloseToast} />

					</Grid>)
			}
		</>
	)
}

export default AppointmentConfirmed
