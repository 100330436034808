export const Strings = {
	TOLL_FREE: 'Toll Free: ',
	FIRST_NAME: 'First Name',
	LAST_NAME: 'Last Name',
	SELECT_A_PERSON: 'Select a Person',
	ADD_A_NEW_PERSON: 'Add a New Person',
	REMOVE_USER: 'Remove user', 
	REMOVE_MESSAGE: 'Are you sure you want to remove {USER_NAME} from your account?',
	ON: 'Ontario',
	SK: 'Saskatchewan',
	BC: 'British Columbia',
	USE_MY_LOCN: 'Use My location',
	SEARCH_RESULTS: 'Search Results',
	EARLI_APPOINT: 'Earliest appointment',
	WAIT_TIME: ' min walk-in wait time',
	NO_WALK_INS: 'Not accepting walk-ins at this time',
	NO_WALK_INS_LOC: 'Not accepting walk-ins at this location',
	KM_AWAY: 'km away',
	OPEN_TODAY: 'Open Today',
	USE_LOC_FINDER: 'Use our location finder to find locations near you',
	LOC_STATUS: {
		REDUCED_HOURS: 'Reduced Hours',
		CIVIC_HOLIDAY: 'Civic Holiday Weekend Closures',
		TC: 'Temporarily Closed',
		SD: 'Service Disruption',
		CT: 'Closed Today',
		WIO: 'Walk-in only',
		OT : 'Open Today'
	},
	DIRECTIONS: 'Directions',
	LOC_DETAILS: 'Location Details',

	//CTA
	VIEW_MORE_LOCS: 'View more Locations',
	VIEW_APPT: 'View Appointment',
	BOOK_HOME_VISIT: 'Book a Home Visit',
	SHOW_LESS: 'Show Less',
	SHOW_MORE: 'Show More',

	BTN_NEXT: 'Next',
	BTN_CANCEL: 'Cancel',
	BTN_CONTINUE: 'Continue',
	BTN_CLOSE: 'Close',
	BTN_YES: 'Yes',
	BTN_NO: 'No',
	BTN_BACK: 'Back',
	LINK_PRINT_DETAILS: 'Print Details',
	LINK_SHARE_BY_MAIL: 'Share by Email',

	STREET_VIEW: 'Street View side-by-side',
	SORT: 'Sort',
	SORT_OPTIONS: [
		{ value: 'CLOSEST', label: 'Closest to me' },
		{ value: 'EARLIEST', label: 'Earliest appointment' },
		{ value: 'SHORTEST', label: 'Shortest walk-in wait time' },
	],
	ERROR_MESSAGES: {
		ZERO_RESULTS:
			'Your search criteria returned 0 results. Please update your selections in the filters and try again',
		NA_SPECIALTY_TEST: 'The selected appointment type is not available in the specified province. We apologize for the inconvenience.',
		NA_SELECTED_SERVICES:
			'The selected services are not available in your area. We apologize for the inconvenience.',
		RECOMMENDED_PSC:
			'Your search criteria returned limited LifeLabs locations within the search radius. The search radius has been expanded to display the next five closest locations.',
	},
	FIND_LOCATION_LABEL: 'Book an appointment ',
	LOCATION_NOTE: 'Locations only in Ontario, British Columbia and Saskatchewan',
	FIND_LOCATION_BTN: 'Find a Location',
	FIND_LOCATION_PLACEHOLDER: 'Search by address, city or postal code',
	CONFIRM_APPT_QUERY: 'Is the appointment for you?',
	CONFIRM_APPT_YES: 'Yes, I am booking for myself',
	CONFIRM_APPT_NO: "No, I am booking for someone else",
	
	ERROR_MINIMUM_STRING:
		'Please enter a minimum of 3 characters in the search bar to get valid results.',
	STANDARD_PREP_HEADER: 'How should I prepare for my test?',
	STANDARD_PREP_BODY:
		'<div><span class="bold">Fasting</span> (no food or drink, except water) <span class="bold">for 8-12 hours beforehand is REQUIRED</span> for thefollowing tests:</div>\n<ul><li>GLUCOSE – fasting</li><li>GTT – gestational diabetes confirmation</li><li>GTT – non pregnant</li><li>LIPIDS/CHOLESTEROL - if indicated by your physician</li></ul>\n<div><span class="bold">Fasting</span> (no food or drink, except water) <span class="bold">for 8-12 hours beforehand is PREFERRED</span> for thefollowing tests:</div>\n<ul><li>Homocysteine</li><li>Iron/Transferrin</li></ul>\n<div><span class="bold">Fasting</span> (no food or drink, except water) <span class="bold">AND no smoking for 4 hours beforehand, AND drinking NO FLUIDS for the lasthour of fasting, is REQUIRED</span> for the following tests:</div>\n<ul><li>H. Pylori (Breath Test)</li></ul>\n\n<div><span class="bold">No preparation</span> is required for the following test:<div>\n<ul><li>H. Pylori (Serology/Blood Test)</li></ul>\n\n<div>The following tests must be completed within <span class="bold">three hours of waking</span>:</div>\n<ul><li>AM Cortisol and Testosterone</li></ul>\n\n<div>For more information, please visit <a href="https://www.lifelabs.com/patients/preparing-for-a-test/patient-test-instructions/" target="_blank">Patient Test Instructions on our website</a>.</div></div></div>',
	CONFIRM_ON_PROVINCE_TITLE: 'Confirm Province: ON LifeLabs Location',
	CONFIRM_BC_PROVINCE_TITLE: 'Confirm Province: BC LifeLabs Location',
	CONFIRM_APPOINTMENT: "Confirm my Appointment",
	MESSAGES: {
		CONFIRM_ON_PROVINCE:
			"Lab results from Ontario (ON) locations are accessible only on the ON/SK version of MyCareCompass. Choosing the BC version means these results won't be available online.",
		CONFIRM_BC_PROVINCE: 
			"Lab results from British Columbia (BC) locations are accessible only on the BC version of MyCareCompass. Choosing the ON/SK version means these results won't be available online.",
		CONFIRM_PROVINCE_ON_QUES:
			'Would you like to switch to the ON/SK version of MyCareCompass?',
		CONFIRM_PROVINCE_BC_QUES:	
			"Would you like to switch to the BC version of MyCareCompass?",
		TIMEOUT: "The confirmation period has timed out. Please select a timeslot to proceed.",
		AUTISM_SUPPORT: 'For Ontario and Saskatchewan autism appointments',
		FIND_ANSWERS: 'Find answers to common questions on '
	},
	APPT_LOST_POPUP: 'Are you sure you want to leave? All your progress will be lost.',

	BOOK_HOME_VISIT_MESS: 'Would you like us to come to you?',
	HOURS_OF_OPERATION: 'Hours of Operation',
	SERVICES_AVAILABLE: 'Services Available',
	AT_THIS_LOCATION: 'At this location',
	SPECIALITY_TESTS: 'Specialty Tests',
	SPECIALITY_TEST: 'Specialty Test',
	TOLERANCE_TEST: 'Tolerance Tests',
	GENERAL_TEST: 'General Test ',
	CLOSED: 'Closed',
	PREPARE_TEST: 'How should I prepare for my test?',
	SELECT_PREF_DATE: 'Select preferred date',
	SELECT_TIME_SLOT: 'Select time slot',
	SELECT_A_TIME: 'Select a time',
	TIME_SLOT: 'Time Slot',
	SERVICES: {
		OPEN_EARLY: 'Opens Early',
		OPEN_SATURDAYS: 'Open Saturdays',
		OPEN_SUNDAYS: 'Open Sundays',
		WHEELCHAIR: 'Wheelchair accessible',
		AUTISM: 'Serving Customers with Autism',
	},
	TESTS: {
		ECG: 'ECG',
		SEMINAL_ANALYSIS: 'Seminal Analysis',
		HOLTER_MONITOR: '24-hour Holter Monitoring',
		CONTRACT: 'Contract & Account Collection',
		AMBP: 'Ambulatory blood pressure monitoring',
		UREA_TEST: 'Urea Breath Test (H Pylori)',
		CRYOGLOBULING: 'Cryoglobulin/ Cryfibrinogen/ Cold Agglutin',
		LEGAL_DRUGS:
			'Medical Legal Drugs of Abuse Collection (including breath, breath and hair)',
		QUANTIFERON: 'QuantiFERON TB Gold',
	},
	SERVICE_FILTER: {
		SERVICES_OFFERED: 'Services Offered ',
		OPEN_EARLY: 'Open before 8:00am',
		OPEN_SAT: 'Open Saturdays',
		OPEN_SUN: 'Open Sundays',
		SERVE_AUTISM: 'Serving customers with autism',
		WHEELCHAIR_ACCESSIBLE: 'Wheelchair accessible',
	},
	SPECIALTY_FILTER: {
		SPECIALTY_TEST: 'Specialty Test ',
		ECG: 'ECG',
		HOLTER_MONITORING: '24-hour Holter Monitoring',
		SEMINAL_ANALYSIS: 'Seminal Analysis',
		AMBULATORY_MONITORING: 'Ambulatory Blood Pressure Monitoring',
		QUANTIEFRON: 'QuantiEFRON TB Gold',
	},
	CONFIRMATION_TEXT: 'Confirmation',
	PREVIOUS_THREE_MONTHS: 'Previous 3 months',
	CANCELLED: 'Cancelled',
	NO_RECORDS_PAST_APPOINTMENT: 'There are no past appointments.',
	NO_RECORDS_UPCOMING_APPOINTMENT: 'There are currently no appointments scheduled.',
	FAQ_LINK: "our FAQ page",
	NO_RECORDS_CANCELLED_APPOINTMENT: "There are no cancelled appointments.",
	"LINK_LAB_APPOINTMENT_BOOKINGS": "Book a Lab Visit",
	LINK_CANCEL_APPOINTMENT: 'Cancel Appointment',
	CANCEL_APPOINTMENT_QUERY: 'Are you sure you want to cancel your appointment?',
	APPOINTMENT_DETAILS: 'Appointment details',
	APPOINTMENTBOOKINGS_BOOKINGSUCCESS_ADDTOCALENDER: 'Add to Calendar',
	APPOINTMENTBOOKINGS_BOOKINGSUCCESS_SHARE: 'Share',
	APPOINTMENTBOOKINGS_BOOKINGSUCCESS_PRINT_VIEW: 'View or Print Details',
	APPOINTMENTBOOKINGS_BOOKINGSUCCESS_APPOINTMENT_CONFIRMED: 'Appointment confirmed!',
	APPOINTMENTBOOKINGS_BOOKINGSUCCESS_EMAIL_SENT: 'A verification email has been sent to: ',
	APPOINTMENTBOOKINGS_APPOINTMENTDURATION: 'Appointment duration: ',
	APPOINTMENTS: 'appointments',
	APPOINTMENTS_CAPTS: ' Appointments',
	LABEL_OR: 'or',
	MY_VISIT_FEE: "charges a convenience fee to cover the certified technician's travel to collect samples from your preferred location. Are you sure you want to proceed to the ",
	LABEL_NOTE: "Note: ",
	MY_VISIT_NOTE: "won't appear in your MyCareCompass list; access them through the ",
	MY_VISIT_LEARN_MORE: "Learn more about ",
	SERVICE_FILTER_TILE: 'Services Offered',
	APPLY: 'Apply',
	CLEAR_ALL: 'Clear All',
	AUTOMATIC_LOGOUT_TIMER: 'Automatic Logout in: ',
	FILTER_BY_DATE: 'Filter by Date',
	PATIENT_INSTRUCTION: 'Patient Test Instruction',
	ALERT_MESSAGES: {
		ZERO_RESULTS:
			'Your search criteria returned 0 results. Please update your selections in the filters and try again',
		NA_SPECIALTY_TEST:
			'The selected appointment type is not available in the specified province. We apologize for the inconvenience.',
		NA_SELECTED_SERVICES:
			'The selected services are not available in your area. We apologize for the inconvenience.',
		RECOMMENDED_PSC:
			'Your search criteria returned limited LifeLabs locations within the search radius. The search radius has been expanded to display the next five closest locations.',
		LOCATIONS_UPDATED:
			'Locations have been updated based on the new criteria applied.',
		ACCOUNT_UPDATED: 'Your informations have successfully been updated to your account',
		USER_ADDED: 'User information has been added successfully',
		APPT_CANCELLATION: 'Appointment was successfully cancelled.',
		NA_APPT_TIMESLOT: 'The appointment timeslot you had selected is no longer available. Please select another timeslot to proceed.',
		APPOINTMENT_TIMEOUT: 'The confirmation period has timed out. Please select a timeslot to proceed.',
		SUCCESS_REMOVE_REGISTRATION: "Record has been removed successfully!",
		CUSTOMER_NOT_MATCH: "Logged In Customer is Not Authorized to cancel the appointment",
	},
	APPOINTMENT_CONFIRMED_PAGE: {
		WHAT_TO_EXPECT: 'What to expect?',
		WHAT_TO_EXPECT_TEXT:
			'To learn more about what to expect during this appointment',
		WHAT_TO_EXPECT_LINK: 'visit our what to expect information page.',
		TEST_PREP: 'Test Prep',
		TEST_PREP_TEXT: 'Some tests require special preparation, ',
		TEST_PREP_LINK:
			'please review these instructions to prepare for the appointment.',
		TITLE: 'What items should I bring to my appointment?',
		DOCUMENTS_1: 'Provincial health card (if applicable)',
		DOCUMENTS_2: 'Completed requisition form, including',
		DOCUMENTS_3: 'Physician signature',
		DOCUMENTS_4:
			'All required information (First and last name, health card number, date of birth, phone number and address)',
		DOCUMENTS_5: 'Other supporting forms (if applicable)',
		DOCUMENTS_6:
			'A form of payment (if applicable, we accept Interac, Mastercard, and Visa)',
		RESULTS_TITLE: 'View Results',
		RESULTS_TEXT:
			'If you are not currently registered to view results online, make sure to ask the lab technician for the Lab Visit Number (LVN) during the appointment. This LVN will be required to complete the Results Registration process on the MyCareCompass Dashboard after the appointment.',
	},
	PATIENT_TEST_INSTRUCTIONS: 'Patient Test Instruction',
	SPECIALTY_TOOLTIP_TITLE: 'Find locations offering specialty test',
	SPECIALTY_TOOLTIP_TEXT:
		'If your requisition indicates that you need a specialty test, ',
	SPECIALTY_TOOLTIP_TEXT_STYLED: 'please apply this filter.',
	YOUR_UPCOMING_APPOINTMENTS: 'Your Upcoming Appointments',
	BOOK_APPOINTMENT_HEADER: "Book an Appointment",
	BOOK_APPOINTMENT_TEXT: 'Book an appointment at a location nearest to you.',
	BOOK_LAB_VISIT: 'Book a Lab Visit',
	BOOK_HOME_VISIT_TEXT: 'Would you like us to come to you?',
	BOOK_HOME_VISIT_LINK: 'Book a Home Visit',
	MY_VISIT_TEXT: 'Exclusively managed through the ', 
	PORTAL: ' portal',
	MY_VISIT_LINK: 'Visit ',
	MY_VISIT_TITLE: 'MyVisitTM appointments',
	VIEW_APPOINTMENTS_UPCOMING: 'Your upcoming appointments',
	AUTISM_PATIENT_SUPPORT: 'Autism Patient Support',
	APPOINTMENT_SUMMARY_AUTISM: '',
	FAQ_TITLE: "Need Help?",
	LINK_CALL_HERE: 'call here',
	"LINK_FAQ": "FAQ",
	"LINK_FAQTUTORIALS": "FAQ & Tutorials",
	"LINK_GO": "Go",
	"LINK_HERE": "here",
	"LINK_HOME": "Home",
	"LINK_LOGIN": "Login",
	"LINK_LOGOUT": "Logout",
	"LINK_MERGE": "Merge",
	"LINK_MYACCOUNT": "My Account",
	"LINK_MYACCOUNTSETTINGS": "Account Settings",
	"LINK_NEXT": "Next",
	"LINK_NO": "No",
	"LINK_OK": "OK",
	"LINK_PRINT": "Print",
	"LINK_PRINTCOPY": "Print Copy",
	"LINK_PRIVACYPOLICY": "Privacy Statement",
	"LINK_REGISTERACCOUNT": "Create an Account",
	"LINK_REGISTERANOTHERUSER": "Add Another Person",
	"LINK_REGISTERUSER": "Add Person",
	"LINK_REMOVE": "Remove",
	"LINK_REJECT": "Reject",
	"LINK_REPORTS": "Reports",
	"LINK_RESETPASSWORD": "Reset Password",
	"LINK_SAVE": "Save",
	"LINK_ACTIVATE": "Activate",
	"LINK_SELECT": "Select",
	"LINK_SENDFORGOTPASSWORDEMAIL": "Send",
	"LINK_SUBMIT": "Submit",
	"LINK_SUPPORT": "Support",
	"LINK_TERMSOFUSE": "Terms of Use",
	"LINK_VIEWPDF": "Print PDF",
	"LINK_YES": "Yes",
	"LINK_DISCARDCHANGES": "Discard Changes",
	"APPOINTMENTBOOKINGS_AUTISM": "Serving Patients with Autism",
	"APPOINTMENTBOOKINGS_AUTISM_CALL1": "Booking Ont. and Sask. autism appointments",
	"APPOINTMENTBOOKINGS_AUTISM_CALL2": "call here",

	NAV: {
		DASHBOARD: "Dashboard",
		REPORTS: "Reports",
		ANALYTICS: "Analytics",
		APPOINTMENTS: "Appointments",
		MARKETPLACE: "Marketplace",
		SHOP_SERVICES: "Shop our Services",
		MY_ACCOUNT: 'My Account',
	},
	CANCEL_APPOINTMENT_HEADER: 'Cancel Appointment',
	CANCEL_APPOINTMENT_BODY: 'Are you sure you want to cancel your appointment?',
	MSG_COMMUNICATION_PREFERENCES_TOOLTIP: "LifeLabs will contact you via email and/or phone number to confirm your appointment and in case there are any issues with your appointment.",	
	MSG_COMMUNICATION_PREFERENCES_CHECKBOX_LABEL_TOOLTIP: "If you no longer wish to receive email notifications about your appointment, please go to My Account > Account Settings > Change Consent to be Contacted and update your preference.",	
	PHONE_NUMBER: "Phone Number",
	EMAIL_ADDRESS: "Email Address",
	LBL_TIMEOUT: "Timeout in",
	QUERY_TIMEOUT_WARNING_BODY:"Your appointment confirmation will timeout in:",
	FIND_LOCATION_PLACEHOLDER_MOBILE: 'Search by address',
	LBL_CANADIAN_COMPANY: 'A Canadian Company',
	LBL_CAREERS: 'Careers',
	LBL_CONTACT_US: 'Contact Us',
	LBL_PAY_AN_INVOICE: 'Pay an Invoice',
	LBL_PATIENTS: 'Patients',
	LBL_HEALTHCARE_PROVIDERS: 'Healthcare Providers',
	LBL_CORPORATE_CUSTOMERS: 'Corporate Customers',
	LBL_TESTS_AND_SERVICES: 'Tests & Services'
}
