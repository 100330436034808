import { generateGUID } from './uuidGenerator'

import { STRINGS } from 'utils/constants/string.constants'

import { ID4_AUTH, CLIENT_ID_ON } from 'utils/constants/routes-constants'
import Cookies from 'js-cookie'
import { store } from 'store'
import { setJWTToken, logOut } from 'store/reducers/authReducer'
import { TOKEN_TYPE } from './constants/common.constants'
import { jwtDecode } from 'jwt-decode'
import { microsoftSupportedTimezones } from './constants/time-constants'

export const getWaitTimeColor = (location) => {
	const waitTime = +location?.waitTime
	const thresholds = [
		{ color: '#1FD531', value: +location?.walkInGreenMinutes },
		{ color: '#FFBB54', value: +location?.walkInYellowMinutes },
		{ color: '#A80014', value: +location?.walkInRedMinutes },
	]

	const validThresholds = thresholds.filter(
		(threshold) => waitTime <= threshold.value
	)

	if (validThresholds.length > 0) {
		const shortestThreshold = validThresholds.reduce((min, threshold) => {
			return threshold.value < min.value ? threshold : min
		})

		return shortestThreshold.color
	}

	return '#A80014'
}

export const getWalkInTimeString = (waitTime) => {
	let waitString = ''
	if (+waitTime > 0) {
		waitString = `${waitTime} ${STRINGS.WAIT_TIME}`
	} else if (waitTime === -1) {
		waitString = STRINGS.NO_WALK_INS
	} else if (waitTime === -2) {
		waitString = STRINGS.NO_WALK_INS_LOC
	}
	return waitString
}

export const getTimeexact = (dateArg) => {
	let providedDateString = dateArg

	// Create a Date object from the provided date string
	let providedDate = new Date(providedDateString)

	// Get the hours, minutes, and seconds from the provided date
	let hours = providedDate.getUTCHours()
	let minutes = providedDate.getUTCMinutes()

	// Determine whether it's AM or PM
	let amOrPm = hours >= 12 ? 'PM' : 'AM'

	// Convert hours to 12-hour format
	hours = hours % 12
	hours = hours ? hours : 12 // Handle midnight (0 hours)

	// Format the time
	let formattedTime =
		hours.toString().padStart(2, '0') +
		':' +
		minutes.toString().padStart(2, '0') +
		' ' +
		amOrPm
	return formattedTime
}

/* istanbul ignore next */
export const checkOpenDate = (dateArg, timeZone) => {
	const selectedTimeZone = microsoftSupportedTimezones.find(
		(zone) => zone.name === timeZone
	)
	const providedDateString = dateArg
	const today = new Date(
		new Date().toLocaleString('en-US', {
			timeZone: selectedTimeZone?.browserTimezone || 'America/New_York',
		})
	)
	const todayDateString = today.toISOString().slice(0, 10)
	const providedDateDateString = providedDateString?.slice(0, 10)
	if (providedDateDateString === todayDateString) {
		return true
	} else {
		return false
	}
}

export const getLocation = (site = {}) => {
	return (site.address1 || '') + (site.address2 || '')
}

export const getHighlightedText = (text, highlight) => {
	const escapeRegExp = (string) => {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
	}

	const escapedHighlight = escapeRegExp(highlight)
	const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'))

	return (
		<span>
			{' '}
			{parts.map((part, i) => (
				<span
					key={i}
					style={
						part.toLowerCase() === highlight.toLowerCase()
							? { fontWeight: 'bold' }
							: {}
					}
				>
					{part}
				</span>
			))}{' '}
		</span>
	)
}

export const getMarkerIcon = (text = '', fill = '#13499F') => {
	var template = `<svg width="60" height="72"
        viewBox="0 0 60 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path
                d="M51.3333 23.7134C51.3333 33.7608 36.0993 53.878 31.1792 60.1133C30.3997 61.1011 28.9336 61.1011 28.1541 60.1133C23.234 53.878 8.00001 33.7607 8.00001 23.7134C7.99768 20.8643 8.55581 18.0426 9.64251 15.4095C10.7292 12.7764 12.3232 10.3833 14.3335 8.36705C16.3438 6.35077 18.731 4.75071 21.3588 3.65824C23.9867 2.56578 26.8036 2.00234 29.6489 2.00001C32.4942 1.99768 35.3122 2.55649 37.9418 3.64465C40.5714 4.73281 42.9612 6.32899 44.9748 8.34198C46.9884 10.355 48.5863 12.7453 49.6773 15.3767C50.7683 18.008 51.331 20.8288 51.3333 23.678L51.3333 23.7134Z"
                fill="${fill}" />
			<text x="24" y="36"
				font-family="Poppins"
				font-size="26"
				fill="white"
			>{{ text }}</text>
		</svg>`
	var svg = template.replace('{{ text }}', text)
	return svg
}

export const getLocationMarkerIcon = () => {
	var template = `<svg 
					xmlns="http://www.w3.org/2000/svg" 
					width="31" 
					height="42" 
					viewBox="0 0 31 42" 
					fill="none">
						<path 
							fill-rule="evenodd" 
							clip-rule="evenodd" 
							d="M16.6742 40.6075C20.5089 35.6948 30.3333 22.4501 30.3333 15.6994L30.3333 15.6746C30.3317 13.6802 29.9378 11.7056 29.1741 9.86368C28.4104 8.02174 27.2919 6.34848 25.8823 4.93939C24.4728 3.5303 22.8 2.41296 20.9592 1.65125C19.1185 0.889542 17.146 0.498374 15.1543 0.500005C13.1625 0.501636 11.1907 0.896044 9.35119 1.66077C7.51171 2.42549 5.84064 3.54554 4.43344 4.95693C3.02624 6.36833 1.91045 8.04345 1.14976 9.88664C0.389064 11.7298 -0.00162419 13.705 5.07485e-06 15.6994C5.07485e-06 22.4501 9.82444 35.6948 13.6591 40.6075C14.4334 41.5993 15.8999 41.5993 16.6742 40.6075ZM15 21.5C18.3137 21.5 21 18.8137 21 15.5C21 12.1863 18.3137 9.5 15 9.5C11.6863 9.5 9.00001 12.1863 9.00001 15.5C9.00001 18.8137 11.6863 21.5 15 21.5Z" 
							fill="#13499F"/>
					</svg>`
	return template
}

export const ERRORS = [
	'MSG_REQUIRED_FIELD',
	'MSG_VALID_FIRST_NAME',
	'MSG_VALID_LAST_NAME',
]

export const getError = (value = '', errors = ERRORS) => {
	if (value.length === 0) {
		return errors[0]
	} else if (value.length > 0) {
		if (isNaN(value.charAt(0)) === false && value.length < 3) {
			return errors[1]
		}
	}
	return ''
}

export const formattedPhoneNumber = (phoneNumber) => {
	const cleanedNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : ''
	let formattedNumber = ''
	if (cleanedNumber.length > 10) {
		return phoneNumber
	}
	if (cleanedNumber.length >= 3) {
		formattedNumber += `(${cleanedNumber.slice(0, 3)})`
	}
	if (cleanedNumber.length >= 6) {
		formattedNumber += ` ${cleanedNumber.slice(3, 6)}`
	} else if (cleanedNumber.length > 3) {
		formattedNumber += ` ${cleanedNumber.slice(3)}`
	}
	if (cleanedNumber.length >= 7) {
		formattedNumber += `-${cleanedNumber.slice(6, 10)}`
	} else if (cleanedNumber.length > 6) {
		formattedNumber += `-${cleanedNumber.slice(6)}`
	}
	return cleanedNumber.length > 3 ? formattedNumber : phoneNumber
}

export const validateEmailAddress = (email) => {
	const trimmedEmail = email.trim()
	const maxLengthError = trimmedEmail.length > 254
	const lowerCaseEmail = trimmedEmail.toLowerCase()
	const parts = lowerCaseEmail.split('@')
	const wrongFormatError =
		parts.length !== 2 ||
		!/^[a-z0-9]+([._-][a-z0-9]+)*$/.test(parts[0]) ||
		!/^[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}$/.test(parts[1])

	return maxLengthError || wrongFormatError
}

export const validatePhoneNumber = (phoneNumber) => {
	const cleanPhoneNumber = phoneNumber.replace(/\D/g, '')
	const isValidDigits = /^\d+$/.test(cleanPhoneNumber)
	const isValidLength =
		cleanPhoneNumber.length === 10 && cleanPhoneNumber.charAt(0) !== '1'
	return !isValidDigits || !isValidLength
}

export const getLoginRedirect = () => {
	let returnURL = ''
	const result = generateGUID()
	// const locale = 'en'
	// const screenType = ''

	if (process.env.REACT_APP_ENV === 'dev')
		returnURL = 'http://localhost:3000/login-return'
	else
		returnURL =
			'https://app-exc-cac-pwt-apptbook-qa-001.azurewebsites.net/login-return'

	const loginURL = ID4_AUTH.replace('{REDIRECT_URL}', returnURL)

	return loginURL
}

export const getAuthToken = () => {
	const authToken = Cookies.get('authToken')
	return authToken
}

export const getTokenType = () => {
	return sessionStorage.getItem('tokenType')
}

export const setAuthToken = async (token, expirationSecond = 300) => {
	if (getTokenType() === TOKEN_TYPE.ID4) {
		let expirationTime = new Date(
			new Date().getTime() + expirationSecond * 60 * 1000
		)
		Cookies.set('authToken', token, {
			expires: expirationTime,
			secure: true,
			sameSite: 'none',
		})
	}
	store.dispatch(setJWTToken(token))
}

export const removeAuthToken = () => {
	Cookies.remove('authToken')
	store.dispatch(logOut())
}

export const inIframe = () => {
	try {
		return window.self !== window.top
	} catch (e) {
		return true
	}
}

export const getSource = () => {
	const currentUrl = window.location.href
	const searchqueryParams = new URL(currentUrl)
	const source = searchqueryParams.searchParams.get('source')
	return source
}

export const saveTokenType = (tokenType) => {
	sessionStorage.setItem('tokenType', tokenType)
}

export const removeTokenType = () => {
	return sessionStorage.removeItem('tokenType')
}

export const decodeJwtToken = (encodedToken) => {
	const decodedToken = jwtDecode(encodedToken)
	return decodedToken
}

export const saveItem = (item, value) => {
	try {
		return sessionStorage.setItem(item, value)
	} catch (error) {
		console.log(error)
	}
}

export const getItem = (item) => {
	try {
		return sessionStorage.getItem(item)
	} catch (error) {
		console.log(error)
	}
}

export const removeItem = (item) => {
	try {
		sessionStorage.removeItem(item)
	} catch (error) {
		console.log(error)
	}
}

/* istanbul ignore next */
export const getProvinceInitials = (province) => {
	if (province === 'ON' || province?.toLowerCase() === 'ontario') {
		return 'ON'
	} else if (
		province === 'BC' ||
		province?.toLowerCase() === 'british columbia'
	) {
		return 'BC'
	} else if (province === 'SK' || province?.toLowerCase() === 'saskatchewan') {
		return 'SK'
	} else {
		return 'ON'
	}
}

// export const convertToDate = (timeString) => {
// 	if(timeString === null || timeString === '' || timeString === undefined){
// 		return timeString;
// 	}
//     let timePart = timeString.replace('Today at ', '');
//     let today = new Date();
//     let dateString = `${today.toDateString()} ${timePart}`;
//     return new Date(dateString);
// }

export const createReservationTimer = () => {
	const currentDate = new Date()
	localStorage.setItem('reservationCreationTime', currentDate)
}

export const removeItemFromBrowserStorage = (item) => {
	if (process.env.REACT_APP_USER_BROWSER_STORAGE === 'sessionStorage') {
		sessionStorage.removeItem(item)
	} else {
		sessionStorage.removeItem(item)
	}
}

export const getItemFromBrowserStorage = (item) => {
	if (process.env.REACT_APP_USER_BROWSER_STORAGE === 'sessionStorage') {
		return sessionStorage.getItem(item)
	} else {
		return sessionStorage.getItem(item)
	}
}

export const setItemFromBrowserStorage = (item, value) => {
	if (process.env.REACT_APP_USER_BROWSER_STORAGE === 'sessionStorage') {
		sessionStorage.setItem(item, value)
	} else {
		sessionStorage.setItem(item, value)
	}
}

export const getAppointmentType = (appointment) => {
	const specialtyName = appointment?.specialtyTest?.name
	const toleranceName = appointment?.toleranceTest?.name

	if (specialtyName && toleranceName) {
		return toleranceName
	} else if (specialtyName) {
		return specialtyName
	} else if (toleranceName) {
		return toleranceName
	} else {
		return 'General'
	}
}

export const removeCachingForKeys = (state, source, keys) => {
	if(source === 'D365' && keys?.length > 0){
		keys.forEach(key => {
            delete state[key];
        });
	}
}

export const generateRandomString = (length = 24) => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
}
