import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue) => {

    const [storedValue, setStoredValue] = useState(() => {
        try {
            let value; 
            if(process.env.REACT_APP_USER_BROWSER_STORAGE === 'sessionStorage'){
                value = window.sessionStorage.getItem(keyName);
            } else {
                value = window.sessionStorage.getItem(keyName);
            }
            if (value) {
                return JSON.parse(value);
            } else {
                if(process.env.REACT_APP_USER_BROWSER_STORAGE === 'sessionStorage'){
                    window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
                } else {
                    window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
                }
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });

    const setValue = (newValue) => {
        try {
            if(process.env.REACT_APP_USER_BROWSER_STORAGE === 'sessionStorage'){
                window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
            } else {
                window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
            }
        } catch (err) { 
            console.log(err)
        }
        setStoredValue(newValue);
    };
    return [storedValue, setValue];
};