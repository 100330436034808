import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TrashIcon from 'assets/TrashIcon'
import Text from 'components/Text'
import RoundedButton from 'components/buttons/RoundedButton'
import { testAttr } from 'utils/test/test.utils'
import { ReactComponent as AppointmentConfirmedCheck } from 'assets/AppointmentConfirmedCheck.svg'
import IconGrid from 'components/data-display/IconGrid/IconGrid'
import { useEffect, useState } from 'react'
import TextButton from 'components/buttons/TextButton'
import { useNavigate } from 'react-router'
import { HOME, LOGIN, MCC_DASHBOARD } from 'utils/constants/routes-constants'
import { AppointmentStatus } from 'utils/constants/status-constants'
import {
	useLazyPostAppointmentsQuery,
	usePutUpdateAppointmentDetailsMutation,
	useEasyCancelAppointmentMutation
} from 'store/services/appointmentApi'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedProvince } from 'store/reducers/selectedProvinceReducer'
import { useLazyGetCustomerDataQuery } from 'store/services/customerApi'
import { useAuth } from 'hooks/useAuth'
import { Alert, Snackbar } from '@mui/material'
import { Strings } from 'utils/Strings'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'
import { resetLoaderStatus, showLoader } from 'store/reducers/loaderReducer'
import Loader from 'components/Loader'

/* istanbul ignore next */
const CancelAppointment = ({ activityId, userId, path, hash }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [isApptCancelled, setIsApptCancelled] = useState(false)
	const [isApptCancelledAlready, setIsApptCancelledAlready] = useState(false)

	const [appointmentId, setAppointmentId] = useState()
	const [customerId, setCustomerId] = useState()
	const [tokenCustomerId, setTokenCustomerId] = useState()

	const [cancelAppointment, { isSuccess }] =
		usePutUpdateAppointmentDetailsMutation()
	const [cancelAppointmentWithHash, { isCancelSuccess }] = useEasyCancelAppointmentMutation()
	const [postAppointments, { data: appointmentList, error, loading }] =
		useLazyPostAppointmentsQuery()
	const [getCustomerData, { data: customerData }] =
		useLazyGetCustomerDataQuery('');
	const [openToast, setOpenToast] = useState(false)
	const selectedProvince = useSelector((state) => state.selectedProvince)

	const { user } = useAuth()

	const onClickHandlerSubmit = () => {
		if (hash) {
			cancelAppointmentWithHash({
				id: activityId,
				hash: hash,
				appointmentStatus: AppointmentStatus.CUSTOMER_CANCELLATION
			})
		}
		else {
			cancelAppointment({
				id: activityId === undefined ? appointmentId : activityId,
				appointmentStatus: AppointmentStatus.CUSTOMER_CANCELLATION,
			})
		}
	}

	const onClickHandlerClose = () => {
		if (activityId) {
			navigate(LOGIN)
		} else {
			navigate(MCC_DASHBOARD)
		}
	}

	useEffect(() => {
		if (tokenCustomerId && customerId) {
			if (tokenCustomerId !== customerId) {
				setOpenToast(true)
				setTimeout(()=>{
					window.location.href = MCCRedirectionUrls.Authenticated_Location_Finder.replace(
						'selectedProvince',
						selectedProvince?.toLowerCase()
					)
				},5000)
			}
		}
	}, [tokenCustomerId])

	useEffect(() => {
		if (userId || customerId) {
			dispatch(showLoader())
			const get_customer = getCustomerData(user.accountId)
			get_customer
				.then((datalist) => {
					setTokenCustomerId(datalist?.data.id)
					dispatch(resetLoaderStatus())
				})
				.catch((error) => {
					dispatch(resetLoaderStatus())
					console.error('Error fetching customer data:', error)
				})

			postAppointments({
				customerId: userId || customerId,
			})
		}
	}, [userId, customerId])

	useEffect(() => {
		if (path && path === 'APPT_CONFIRMED') {
			setIsApptCancelled(true)
			setIsApptCancelledAlready(true)
		}
	}, [path])

	useEffect(() => {
		if (sessionStorage.getItem('activityId')) {
			setAppointmentId(sessionStorage.getItem('activityId'))
			activityId = sessionStorage.getItem('activityId');
			sessionStorage.removeItem('activityId')
		}

		if (sessionStorage.getItem('customerId')) {
			setCustomerId(sessionStorage.getItem('customerId'))
			userId = sessionStorage.getItem('customerId');
			sessionStorage.removeItem('customerId')
		}
	}, [appointmentId])

	useEffect(() => {
		if (appointmentList && (activityId || appointmentId)) {

			let bookedAppoinment

			if (
				appointmentList?.cancelledAppointments &&
				appointmentList?.cancelledAppointments?.length > 0
			) {
				bookedAppoinment = appointmentList?.cancelledAppointments.find(
					(appt) => appt?.activityId === (activityId || appointmentId)
				)
				if (bookedAppoinment) {
					setIsApptCancelled(true)
					setIsApptCancelledAlready(true)
				}
			}

			if (
				appointmentList?.pastAppointments &&
				appointmentList?.pastAppointments?.length > 0 &&
				appointmentList?.pastAppointments.find(
					(appt) => appt?.activityId === (activityId || appointmentId)
				)
			) {
				bookedAppoinment = appointmentList?.pastAppointments.find(
					(appt) => appt?.activityId === (activityId || appointmentId)
				)
			}

			if (
				appointmentList?.upcomingAppointments &&
				appointmentList?.upcomingAppointments?.length > 0 &&
				appointmentList?.upcomingAppointments.find(
					(appt) => appt?.activityId === (activityId || appointmentId)
				)
			) {
				bookedAppoinment = appointmentList?.upcomingAppointments.find(
					(appt) => appt?.activityId === (activityId || appointmentId)
				)
			}

		}
	}, [appointmentList, activityId, appointmentId])

	const handleClose = () => {
		setOpenToast(false)
	}

	useEffect(()=>{
		sessionStorage.removeItem('redirectionPath');
	},[])

	if (loading) {
		return <></>
	}

	return (
		<Stack
			justifyContent="center"
			sx={{
				width: 'auto',
				height: '90vh',
				flexWrap: {
					xs: 'inherit',
					md: 'wrap',
				},
				alignContent: {
					xs: 'inherit',
					md: 'center',
				},
			}}
			pl={2}
			pr={2}
		>
			<Loader />
			{
				openToast ? null :
					isApptCancelled ? (
						<>
							<IconGrid
								sx={{
									marginBottom: {
										xs: '16px',
										md: '0',
									},
								}}
								alignItems="center"
								muiIcon={<AppointmentConfirmedCheck />}
							>
								<Text
									variant="h1"
									i18nKey="MSG_BOOKINGSUCCESS_APPOINTMENT_CANCELLED"
									ml={2}
									sx={{
										fontSize: {
											xs: '28px',
											md: '36px',
										},
										lineHeight: {
											xs: '2.375rem',
											md: '4.125rem',
										},
									}}
								/>
							</IconGrid>

							{path && path === 'APPT_CONFIRMED' ? null : (
								<Text variant="paragraph3" i18nKey="MSG_APPOINTMENT_CANCELLED" />
							)}

							{!isApptCancelledAlready ? (
								<Grid
									item
									sx={{
										flexDirection: {
											xs: 'column',
											md: 'row',
										},
										display: 'flex',
										alignSelf: {
											xs: 'flex-start',
											md: 'auto',
										},
										marginTop: {
											xs: '16px',
											md: '60px',
										},
									}}
									justifyContent={'flex-start'}
									gap={6}
								>
									<Stack
										item
										xs={12}
										md={6}
										alignItems="start"
										pl={4}
										borderLeft={1}
									>
										<Text.Bold mb={2} i18nKey="LBL_MANAGE_APPTS" />
										<TextButton
											onClick={() => {
												navigate(MCC_DASHBOARD, { replace: true })
											}}
											i18nKey="LINK_LOGIN_MCC"
										/>
									</Stack>
									<Stack
										item
										xs={12}
										md={6}
										pl={4}
										alignItems="start"
										borderLeft={1}
									>
										<Text.Bold mb={2} i18nKey="LBL_BOOK_NEW" />
										<TextButton
											onClick={() => {
												navigate(HOME, { replace: true })
												window.location.reload()
											}}
											i18nKey="LINK_VISIT_LOC_FINDER"
										/>
									</Stack>
								</Grid>
							) : null}
						</>
					) : (
						<>
							<Grid
								item
								alignItems="center"
								justifyContent="flex-start"
								mb={2}
								sx={{
									gap: '10px',
									display: 'flex',
								}}
							>
								<TrashIcon fillColor="#000" />
								<Text
									sx={{
										fontSize: {
											xs: '1.72rem',
											md: '2rem',
										},
										fontWeight: {
											xs: '500',
											md: '400',
										},
									}}
									i18nKey={'LBL_CANCEL_APPOINTMENT'}
								/>
							</Grid>
							<Text variant="paragraph2" i18nKey="QUERY_APPOINTMENT_BODY" />
							<Grid marginTop="40px">
								<RoundedButton
									sx={{ fontSize: '1rem', marginRight: 2 }}
									onClick={() => {
										setIsApptCancelled(true)
										onClickHandlerSubmit()
									}}
									{...testAttr(`cancel-appt-yes-btn`)}
									i18nKey={'BTN_YES'}
								/>

								<RoundedButton
									variant="outlined"
									sx={{ fontSize: '1rem' }}
									onClick={onClickHandlerClose}
									{...testAttr(`cancel-appt-no-btn`)}
									i18nKey={'BTN_NO'}
								/>
							</Grid>
						</>
					)}
			<Snackbar
				open={openToast}
				autoHideDuration={10000}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'top'
				}}
				sx={{
					marginTop: '100px',
				}}
			>
				<Alert
					severity='error'
					variant="filled"
					sx={{
						width: '100%',
						padding: '20px'
					}}
				>
					{Strings.ALERT_MESSAGES.CUSTOMER_NOT_MATCH}
				</Alert>
			</Snackbar>
		</Stack>
	)
}

export default CancelAppointment
