import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Backdrop, Box, ClickAwayListener } from '@mui/material'
import { ReactComponent as Loading } from 'assets/Loading.svg'
import { ReactComponent as LoadingMap } from 'assets/LoadingMap.svg'

import Drawer from 'components/Drawer'
import LocationsList from './LocationsList'
import LocationDetails from './location-details/LocationDetails'
import Toast from 'components/feedback/toast/Toast'
import Appointment from 'features/appointment/Appointment'
import useMobile from 'hooks/useMobile'

import { sortFilter } from 'utils/sort.utils'
import { TestType } from 'utils/constants/common.constants'

import './location/location.scss'
import Map from './maps/Map'
import { useDispatch, useSelector } from 'react-redux'
import {
	APPOINTMENT_STATES,
	resetAppointment,
	resetAppointmentLocation,
	updateAppointment,
	updateAppointmentDiscardedStatus,
	updateAppointmentLocation,
	updateAppointmentState,
	resetSelectedAppointmentDateTime
} from 'features/appointment/appointmentSlice'
import { useLazyPostLocationBySiteIdQuery } from 'store/services/locationSiteIdApi'

const SearchResults = ({ searchResults, isResultsLoading, setShowMyVisit }) => {
	const dispatch = useDispatch()
	const isMobile = useMobile()

	const { appointmentState, appointmentDiscardedStatus } = useSelector(
		(state) => state.appointment
	)

	const { specialityTests: selectedSpeciltyTest, date: selectedDate } =
		useSelector((state) => state.filter)
	const sortCriteria = useSelector((state) => state.sortCriteria)
	//Show or hide Toast and dialog
	const [openToast, setOpenToast] = useState(true)
	const [toastCode, setToastCode] = useState(undefined)

	const [showLocationDetails, setShowLocationDetails] = useState(false)

	const [selectedSort, setSelectedSort] = useState(
		sortCriteria?.sortCriteria || ''
	)

	useEffect(() => {
		setSelectedSort(sortCriteria?.sortCriteria)
	}, [sortCriteria])

	const [selectedLocation, setSelectedLocation] = useState(null)
	const [earliestAppDetails, setEarliestAppDetails] = useState()
	const [locations, setLocations] = useState([])
	const [updateEarliestApptTime, setUpdateEarliestApptTime] = useState([])
	const [updateWaitTime, setUpdateWaitTime] = useState([])

	const [paginatedLocationList, setPaginatedLocationList] = useState([])
	const [applyFilterFirstTime, setApplyFilterFirstTime] = useState(false)

	const appliedFilters = useSelector((state) => state.filter)
	const { source } = useSelector((state) => state.search)

	useEffect(() => {
		dispatch(updateAppointmentState(APPOINTMENT_STATES[0]))
	}, [appliedFilters])

	useEffect(() => {
		if (appointmentState) {
			if (appointmentState === APPOINTMENT_STATES[4]) {
				dispatch(updateAppointmentState(APPOINTMENT_STATES[1]))
			}
		}
	}, [appointmentState])

	const [postLocationBySiteId, { data, error, isLoading }] =
		useLazyPostLocationBySiteIdQuery('')
	const [locationDetails, setLocationDetails] = useState({})

	useEffect(() => {
		if (data) {
			let updatedLocationDetails = { ...data }
			const earliestApptMap = updateEarliestApptTime.reduce((map, item) => {
				map[item.siteId] = item
				return map
			}, {})
			const waitTimeMap = updateWaitTime.reduce((map, item) => {
				map[item.siteId] = item
				return map
			}, {})
			

			if (updatedLocationDetails?.earliestAppt === null) {
				updatedLocationDetails.earliestAppt =
					earliestApptMap[updatedLocationDetails?.siteIdGuid]?.earliestTime ||
					null
				updatedLocationDetails.earliestApptOrg =
					earliestApptMap[updatedLocationDetails?.siteIdGuid]
						?.earliestTimeOrg || null
			}

			if (updatedLocationDetails?.waitTime === null) {
				updatedLocationDetails.waitTime =
				waitTimeMap[updatedLocationDetails?.siteIdGuid]?.waitTime ||
					null
			}

			setLocationDetails(updatedLocationDetails)
		}
	}, [data])

	const handleClickDetails = (location) => {
		postLocationBySiteId({
			site_id: [location.siteIdGuid],
			...(selectedSpeciltyTest && {
				tests: [
					{
						id: selectedSpeciltyTest.id,
						type: TestType.specialityTests,
					},
				],
			}),
		})
		setShowLocationDetails(!showLocationDetails)
		setSelectedLocation(location)
	}

	const commonFunction = (location) => {
		setShowLocationDetails(false)
		setSelectedLocation(location)
		dispatch(updateAppointmentLocation(location))
		dispatch(updateAppointmentState(APPOINTMENT_STATES[1]))
	}

	const handleEarlApptClick = (location) => {
		commonFunction(location)
		dispatch(
			updateAppointment({
				appointmentDate: location.earliestAppDetails,
				appointmentTime: location.earliestAppDetails,
			})
		)
		setEarliestAppDetails(location.earliestApptOrg)
	}

	const handleClickViewAppt = (location) => {
		commonFunction(location)
		dispatch(resetAppointment())
		dispatch(resetSelectedAppointmentDateTime())
	}

	const onClickHandlerCloseDrawerBtn = () => {
		dispatch(resetAppointment())
		dispatch(resetAppointmentLocation())
		dispatch(updateAppointmentState(APPOINTMENT_STATES[0]))
		dispatch(resetSelectedAppointmentDateTime())
		setSelectedLocation()
		setEarliestAppDetails()
	}

	useEffect(() => {
		if (selectedSort) {
			handleLocationSort()
		}
	}, [selectedSort, paginatedLocationList])

	const handleLocationSort = () => {
		const sortedLocations = sortFilter([...paginatedLocationList], selectedSort)
		setPaginatedLocationList((prevLocations) => {
			if (JSON.stringify(sortedLocations) !== JSON.stringify(prevLocations)) {
				return sortedLocations
			}
			return prevLocations
		})
	}

	useEffect(() => {
		if (searchResults?.locations?.length > 0) {
			const locationsWithPosition = [
				...searchResults.locations.map((loc, index) => {
					return {
						position: {
							lat: +loc.address.latitude,
							lng: +loc.address.longitude,
						},
						id: index,
						...loc,
					}
				}),
			]
			setLocations([...sortFilter(locationsWithPosition, 'CLOSEST')])
		} else {
			setLocations([])
			setSelectedLocation(null)
		}
		if(searchResults?.message === 'Your selected filter and type of test did not return any results within 25 km, here are the next recommended PSCs carrying these services in your province'){
			setOpenToast(true)
			setToastCode(100)
		} else {
			setOpenToast(searchResults?.alertCode > 1)
		}
		setUpdateEarliestApptTime([])
		setUpdateWaitTime([])
	}, [searchResults])

	useEffect(() => {
		if (updateEarliestApptTime && updateWaitTime) {

			const earliestApptMap = updateEarliestApptTime.reduce((map, item) => {
				map[item.siteId] = item
				return map
			}, {})

			const waitTimeMap = updateWaitTime.reduce((map, item) => {
				map[item.siteId] = item
				return map
			}, {})
			setPaginatedLocationList((prevLocations) =>
				prevLocations.map((location) => {
						return {
							...location,
							earliestAppt:
								location?.earliestAppt === null
									? earliestApptMap[location?.siteIdGuid]?.earliestTime || null
									: location?.earliestAppt,
							earliestApptOrg:
								location?.earliestAppt === null
									? earliestApptMap[location?.siteIdGuid]?.earliestTimeOrg || null
									: location?.earliestApptOrg,
							waitTime: waitTimeMap[location?.siteIdGuid]?.waitTime
						};
				})
			)
		}
	}, [updateEarliestApptTime, updateWaitTime])

	useEffect(() => {
		if (locations) {
			setPaginatedLocationList(
				locations.slice(0, paginatedLocationList.length || 10)
			)
		}
	}, [locations])

	const handleViewMore = () => {
		const nextChunkSize = paginatedLocationList.length === 10 ? 10 : 5
		const nextPageEndIndex = paginatedLocationList.length + nextChunkSize
		setPaginatedLocationList(locations.slice(0, nextPageEndIndex))
	}

	useEffect(()=>{
		if(paginatedLocationList?.length > 0 && selectedLocation === null && source === 'D365'){
			setSelectedLocation(paginatedLocationList[0])
		}
	},[paginatedLocationList])

	return (
		<>
			<ClickAwayListener
				onClickAway={() => {
					// dispatch(updateAppointmentState(APPOINTMENT_STATES[0]))
				}}
			>
				<Grid
					container
					sx={{
						padding: { xs: '0.2rem', md: '0' },
					}}
				>
					<Grid
						item
						sm={12}
						md={6}
						// className={
						// 	appointmentState === APPOINTMENT_STATES[0] ? '' : 'show-appointment'
						// }
						sx={{
							padding: {
								sm: 0,
								md: '1rem',
								overflowY: 'auto',
								height: '95vh',
							},
						}}
					>
						<>
							{isResultsLoading ? (
								<Box maxWidth="390px">
									<Loading
										style={{
											maxWidth: '390px',
											height: 'auto',
										}}
									/>
								</Box>
							) : (
								<LocationsList
									locations={locations}
									selectedSort={selectedSort}
									setSelectedSort={setSelectedSort}
									handleClickDetails={handleClickDetails}
									handleEarlApptClick={handleEarlApptClick}
									handleClickViewAppt={handleClickViewAppt}
									selectedLocation={selectedLocation}
									setSelectedLocation={setSelectedLocation}
									setShowMyVisit={setShowMyVisit}
									setUpdateEarliestApptTime={setUpdateEarliestApptTime}
									handleViewMore={handleViewMore}
									paginatedLocationList={paginatedLocationList}
									setUpdateWaitTime={setUpdateWaitTime}
								/>
							)}
							<Backdrop
								sx={{
									display: {
										xs: 'none',
									},
								}}
								open={appointmentState === APPOINTMENT_STATES[1]}
							/>
						</>
					</Grid>

					<Grid
						item
						sm={0}
						md={6}
						xs={12}
						sx={{ position: { xs: 'initial', md: 'relative' } }}
					>
						<Map
							markers={paginatedLocationList}
							handleClickDetails={handleClickDetails}
							handleEarlApptClick={handleEarlApptClick}
							handleClickViewAppt={handleClickViewAppt}
							setSelectedLocation={setSelectedLocation}
							selectedLocation={selectedLocation}
							className={
								!isLoading && appointmentState === APPOINTMENT_STATES[0]
									? isMobile
										? 'map-container-mobile'
										: 'map-container'
									: ''
							}
						/>

						{isLoading && <LoadingMap />}
						{appointmentState === APPOINTMENT_STATES[1] && (
							<Drawer
								aria-label="Book appointment drawer"
								drawerId="book-appt"
								isOpen={APPOINTMENT_STATES[1]}
								onClickHandlerCloseBtn={onClickHandlerCloseDrawerBtn}
								sx={{
									zIndex: appointmentState === APPOINTMENT_STATES[1] ? 10 : -1,
								}}
							>
								<Appointment
									selectedSpeciltyTest={selectedSpeciltyTest}
									selectedFilterDate={selectedDate}
									selectedEarliest={earliestAppDetails}
								/>
							</Drawer>
						)}
					</Grid>
				</Grid>
			</ClickAwayListener>

			<LocationDetails
				isLoading={isLoading}
				distance={selectedLocation?.address?.distance}
				showLocationDetails={showLocationDetails}
				locationDetails={locationDetails}
				handleClickDetails={handleClickDetails}
				handleEarlApptClick={handleEarlApptClick}
				handleClickViewAppt={handleClickViewAppt}
			/>

			<Toast
				open={openToast}
				handleClose={() => {
					setOpenToast(false)
					setToastCode(undefined)
				}}
				alertCode={toastCode || searchResults?.alertCode}
			/>

			<Toast
				open={appointmentDiscardedStatus}
				handleClose={() => dispatch(updateAppointmentDiscardedStatus(false))}
				alertCode={250}
			/>
		</>
	)
}

export default SearchResults
