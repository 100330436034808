import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { AppBar, Typography } from '@mui/material'
import ConfirmDialog from 'components/ConfirmDialog'
import MCCHeader from './mcc'
import { useAuth } from 'hooks/useAuth'
import {
	APPOINTMENTS,
	CONFIRM_APPT,
	HOME,
	LOCATION_FINDER,
	MCC_CONFIRM_APPT,
	MCC_DASHBOARD,
} from 'utils/constants/routes-constants'
import { Strings } from 'utils/Strings'
import { testAttr } from 'utils/test/test.utils'
import LifelabsHeader from './lifelabs/lifelabs.header'
import useMobile from 'hooks/useMobile'
import { useDispatch, useSelector } from 'react-redux'

import Text from 'components/Text'

import {
	APPOINTMENT_STATES,
	resetAppointReserveId,
	resetAppointmentTime,
	updateAppointmentState,
} from 'features/appointment/appointmentSlice'

import { useDeleteReservationMutation } from 'store/services/reservationApi'
import { useUpdateDeviceHistoryMutation } from 'store/services/deviceApi'

import { DEVICE_HISTORY_STATUSES } from 'utils/constants/common.constants'

import TrashIcon from 'assets/TrashIcon'

/* istanbul ignore next */
const Header = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const auth = useAuth()

	const isMobile = useMobile()

	const source = useSelector((state) => state.search.source)
	const selectedProvince = useSelector((state) => state.selectedProvince)
	const isAppointmentPath = location?.pathname.includes(APPOINTMENTS)
	const selectedAppointmentDateTime = useSelector((state) => state.appointment.selectedAppointmentDateTime)

	const appointmentReserveId = useSelector(
		(state) => state.appointment.appointmentReserveId
	)
	const deviceHistoryDetails = useSelector((state) => state.deviceHistory)

	const [deleteReservation] = useDeleteReservationMutation()
	const [updateDeviceHistory] = useUpdateDeviceHistoryMutation()

	const isLoggedIn = auth?.user

	const [openDialog, setOpenDialog] = useState(false)
	const [openDiscardAppt, setOpenDiscardAppt] = useState(false)

	const [redirectionURL, setRedirectionURL] = useState()

	const onClickHandleSubmit = () => {
		setOpenDialog(false)
		if (redirectionURL.includes('{province}')) {
			window.location.href = redirectionURL
				.replace('{province}', selectedProvince?.toLowerCase())
		} else {
			window.location.href = redirectionURL
		}
	}

	const showProgressPopup = (redirectionUrl) => {
		setRedirectionURL(redirectionUrl)

		if (window.location.pathname === MCC_CONFIRM_APPT) {
			setOpenDiscardAppt(true)
		} 
		else {
			if (redirectionUrl.includes('{province}')) {
				window.location.href = redirectionUrl.replace(
					'{province}',
					selectedProvince?.toLowerCase()
				)
			} else {
				window.location.href = redirectionUrl
			}
		}
	}

	const apptDiscarded = () => {
		deleteReservation(appointmentReserveId).then(async () => {
			if (deviceHistoryDetails?.deviceHistoryId) {
				updateDeviceHistory({
					id: deviceHistoryDetails?.deviceHistoryId,
					StatusReason: DEVICE_HISTORY_STATUSES.Delete,
				})
			}

			await Promise.all([
				dispatch(updateAppointmentState(APPOINTMENT_STATES[4])),
				dispatch(resetAppointmentTime()),
				dispatch(resetAppointReserveId()),
			])

			setOpenDiscardAppt(false)

			if (redirectionURL.includes('{province}')) {
				window.location.href = redirectionURL
					.replace('{province}', selectedProvince?.toLowerCase())
					.concat('&discardappointment=1')
			} else {
				window.location.href = redirectionURL.concat('&discardappointment=1')
			}
		})
	}

	/* istanbul ignore next */
	return (
		<AppBar
			position="static"
			sx={{
				backgroundColor: 'info.dark',
				boxShadow: 'none',
				top: '0',
			}}
			role="banner"
		>
			{isLoggedIn ? (
				source !== 'D365' ? (
					<MCCHeader
						isLoggedIn={true}
						showProgressPopup={showProgressPopup}
						checkShopNowConfig={true}
						checkMarketPlaceConfig={true}
					/>
				) : null
			) : (
				<LifelabsHeader isMobile={isMobile} />
			)}

			<ConfirmDialog
				confirmDialogId="appointment-lost-dialog"
				openDialog={openDialog}
				onClickHandlerClose={() => setOpenDialog(false)}
				onclickHandlerSubmit={onClickHandleSubmit}
			>
				<Typography
					variant="paragraph2"
					sx={{
						margin: '1rem 0 0 0',
						fontWeight: '600',
					}}
				>
					{Strings.APPT_LOST_POPUP}
				</Typography>
			</ConfirmDialog>

			<ConfirmDialog
				confirmDialogId="cancel-appt-modal"
				openDialog={openDiscardAppt}
				i18nKeyForHeader="BTN_DISCARD_APPOINTMENT"
				confirmDiaglogHeaderIcon={<TrashIcon fillColor="#000" />}
				onClickHandlerClose={() => {
					setOpenDiscardAppt(false)
					setRedirectionURL()
				}}
				onclickHandlerSubmit={apptDiscarded}
				i18nKeyForBtns={['BTN_DISCARD_APPOINTMENT', 'BTN_CONTINUE_BOOKING']}
			>
				<Text
					variant="paragraph2"
					i18nKey="QUERY_DISCARD_APPOINTMENT"
					sx={{
						fontWeight: {
							xs: '600',
							md: '400',
						},
					}}
				/>
			</ConfirmDialog>
		</AppBar>
	)
}

export default Header
