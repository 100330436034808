export const languages = {
    en: "en",
    fr: "fr",
};

export const STATES = [
    {
        name: 'ON',
        number: "1-877-849-3637",
        openHours: 'Mon - Fri from 7AM - 7PM (ET)'
    },
    {
        name: 'SK',
        number: "1-888-333-0222",
        openHours: 'Mon - Fri from 11AM - 7PM (CT)'
    },
    {
        name: 'BC',
        number: "1-800-431-7206",
        openHours: 'Mon - Fri from 9AM - 5PM (PT)'
    }
]

export const TestType = {
    specialityTests: 1,
    toleranceTests: 2
}

export const BookingType = [
    {
        name: 'CSR',
        bookingId: "178550000"
    },
    {
        name: 'PSC',
        bookingId: "178550001"
    },
    {
        name: 'WEB',
        bookingId: "178550002"
    },
]

export const TOKEN_TYPE = {
    ID4 :'ID4',
    AZURE_AD: 'AZURE_AD'
}

export const DEVICE_HISTORY_STATUSES = {
    "Create": '1',
    "Update": '178550001',
    "Delete": '2'
}

export const APPOINTMENT_TYPE = {
    "Staff": 2,
    "PrimaryCustomer": 1,
    "AdhocCustomer": 3
}

export const AUTISM_MODAL_OPEN_HOURS = {
    "ON": 'Mon - Fri from 7AM - 7PM (ET)',
    "SK": 'Mon - Fri from 11AM - 7PM (CT)',
    "BC": 'Mon - Fri from 9AM - 5PM (PT)'
}

export const FEATUREFLAGS ={
    MyVisit: "ON"
}