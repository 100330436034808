import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { removeItemFromBrowserStorage } from 'utils/common.utils'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'

export const useIdleTimeout = ({ idleTime }) => {
    let idleTimeOut = (idleTime == null) ? (5 * 60000) : (idleTime * 60000); 
    let expireTime = Date.now() + idleTimeOut;
    const [logout, setLogout] =  useState(false);
    const [tik, setTik] =  useState(Date.now());
    const idToken = useSelector((state) => state.auth?.idToken);
    const selectedProvince = useSelector((state) => state.selectedProvince);

    useEffect(() => {
        if (logout && idToken != null) {
            removeItemFromBrowserStorage('user');
            const idp_url = selectedProvince.toLowerCase() == 'bc' ? 
                process.env.REACT_APP_IDP_BC : process.env.REACT_APP_IDP_ON;
            const mccUrl = selectedProvince.toLowerCase() == 'bc' ? 
                `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}/?province=${selectedProvince}` 
                : `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}/?province=${selectedProvince}`
            window.location.href = MCCRedirectionUrls.SessionTimeout.replace('{IDP_URL}', idp_url).replace('{ID_TOKEN}', idToken).replace('{MCC_LOGIN_URL}', mccUrl);
        } else {
            setLogout(false);
        }
    }, [tik]);
    
    const checkForAnInactivity = () => {
        if (expireTime < Date.now()) {
            setLogout(true);
            setTik(Date.now())
        }
    }

    const updateExpiryTime = () => {
        expireTime = Date.now() + idleTimeOut;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkForAnInactivity();
        }, 2000);
            
        return () => clearInterval(interval); 
    }, [])

    useEffect(() => {
        window.addEventListener("click", updateExpiryTime);
        window.addEventListener("keypress", updateExpiryTime);
        window.addEventListener("scroll", updateExpiryTime);
        window.addEventListener("mousemove", updateExpiryTime);
        return () => {
            window.removeEventListener("click", updateExpiryTime);
            window.removeEventListener("keypress", updateExpiryTime);
            window.removeEventListener("scroll", updateExpiryTime);
            window.removeEventListener("mousemove", updateExpiryTime);
        }
    }, [])
}