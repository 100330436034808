import { Box, Grid, IconButton, Stack } from "@mui/material";
import { ReactComponent as CloseTooltip } from 'assets/FilterIcons/CloseTooltip.svg'
import { ReactComponent as SearchBlue } from 'assets/FilterIcons/SearchBlue.svg'
import Text from "components/Text";
import IconGrid from "components/data-display/IconGrid/IconGrid";

const CustomAppointmentToolTip = ({ setIsTooltipOpen, label }) => {
    return (
        <Stack direction="row" alignItems="start" tabIndex={-1}>
            <Box>
                <Text
                    variant="paragraph3"
                    sx={{ color: '#13499F', fontWeight: "400" }}
                    i18nKey={label || 'MSG_COMMUNICATION_PREFERENCES_TOOLTIP'}
                />
            </Box>
            <IconButton
                width="12px"
                tabIndex={0}
                height="24px"
                onKeyUpCapture={(event) => {
                    if (event.key === 'Enter') {
                        setIsTooltipOpen(false);
                    }
                }}
                onClick={() => setIsTooltipOpen(false)}
                data-testid="close-tooltip-button"
                sx={{
                    marginTop: '-5px'
                }}
            >
                <CloseTooltip />
            </IconButton>
        </Stack>
    )
}

export default CustomAppointmentToolTip;